
import api, { useApi } from '@/api'
import dayTemplateForm from '@/components/day-template-form.vue'
import { DayTemplateDto } from 'service/__generated-api'
import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  components: { dayTemplateForm },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const dayTemplateId = parseInt(route.params.id as string)

    if (Number.isNaN(dayTemplateId)) {
      router.replace('/my-temaples')
    }

    const dayTemplateRes = useApi(api =>
      api.dayTemplates.getById(dayTemplateId)
    )

    const isLoading = ref(false)
    async function onDayTemplateEdit(dayTemplate: DayTemplateDto) {
      if (isLoading.value === true) return
      isLoading.value = true

      try {
        await api.dayTemplates.update(dayTemplateId, {
          periods: dayTemplate.periods,
          name: dayTemplate.name,
          remote: dayTemplate.remote,
          comment: dayTemplate.comment,
          notAvailable: false
        })

        router.push('/my-templates')
      } catch (err) {
        console.error(err)
      }

      isLoading.value = false
    }

    return {
      isDayTemplateLoading: dayTemplateRes.isLoading,
      dayTemplate: dayTemplateRes.data,
      onDayTemplateEdit,
      isLoading
    }
  }
})
