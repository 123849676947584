
import { UserDto } from 'service/__generated-api'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    user: {
      required: true,
      type: Object as PropType<UserDto>
    }
  }
})
