<template>
  <td class="user">
    <figure class="image">
      <img class="is-rounded" :src="user.picture" alt="" />
    </figure>
    <component :is="user.picture ? 'span' : 'strong'">
      {{ user.firstName }}
      {{ user.lastName }}
    </component>
  </td>
</template>

<script lang="ts">
import { UserDto } from 'service/__generated-api'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    user: {
      required: true,
      type: Object as PropType<UserDto>
    }
  }
})
</script>

<style lang="scss" scoped>
.user {
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.image {
  display: inline-block !important;
  position: relative;
  top: 4px;
  margin-right: 3px;

  width: 20px;
  height: 20px;
}
</style>
