
import { useApi } from '@/api'
import { formatDate, normalizeArray } from '@/utils'
import { defineComponent, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { addDays, format, parse } from 'date-fns'
import UserRow from '@/components/calendars/team-daily-user-row.vue'
import TimeSwitcher from '@/components/time-switcher.vue'

export default defineComponent({
  components: { UserRow, TimeSwitcher },
  setup() {
    /**
     * Current day state
     */
    const route = useRoute()
    const routeDate = `${route.params.formattedDate}`
    const currentDate = ref(
      /^\d{4}-\d\d-\d\d$/.test(routeDate)
        ? parse(routeDate, 'yyyy-MM-dd', new Date())
        : new Date()
    )
    const routeIds = route.query.teamsIds
    const teamsIds = normalizeArray(routeIds).map(Number)

    /**
     * Api call
     */
    const usersRes = useApi(api =>
      api.timeline.daily(formatDate(currentDate.value), { teamsIds })
    )
    const isLoading = usersRes.isLoading
    const users = usersRes.data

    /**
     * User periods bars calc
     */
    let minHour = 23
    let maxHour = 0
    const hours = ref<number[]>([])

    watch(users, () => {
      if (!users.value) return

      for (const user of users.value) {
        const day = user.days[0]
        const periods = day ? day.periods.split(',') : ['8:00-16:00']
        const firstPeriod = periods[0]
        const lastPeriod = periods[periods.length - 1]

        const startHour = parseInt(firstPeriod) - 1
        if (startHour < minHour) minHour = startHour

        const endHour = parseInt(lastPeriod.split('-')[1])
        if (endHour > maxHour) maxHour = endHour
      }

      for (let i = minHour; i <= maxHour; i++) {
        hours.value.push(i)
      }

      // If nobody works this day:
      if (hours.value.length === 0) {
        hours.value = [8, 9, 10, 11, 12, 13, 14, 15, 16]
      }
    })

    /**
     * Routing
     */
    const router = useRouter()
    function nextDay() {
      router.push({
        ...route,
        params: { formattedDate: formatDate(addDays(currentDate.value, 1)) }
      })
    }

    function previousDay() {
      router.push({
        ...route,
        params: { formattedDate: formatDate(addDays(currentDate.value, -1)) }
      })
    }

    /**
     * Return
     */
    return {
      format,
      currentDate,
      nextDay,
      previousDay,
      users,
      hours,
      isLoading
    }
  }
})
