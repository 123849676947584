<template>
  <img :src="icon.url" :title="icon.name" :alt="icon.name" class="img" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

enum IconName {
  Kaktus = 'kaktus',
  Office = 'office',
  Remote = 'remote',
  NotAvailable = 'not-available',
  AbsenceInReview = 'absence-in-review',
  Comment = 'comment',
  Thunder = 'thunder',
  Sick = 'sick',
  Course = 'course',
  HalfDay = 'half-day'
}

export default defineComponent({
  props: {
    name: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const iconMap = {
      [IconName.Kaktus]: {
        name: 'Kaktus',
        url: 'https://mattermost.sparing.cloud/static/emoji/1f335.png'
      },
      [IconName.Office]: {
        name: 'Office',
        url: 'https://mattermost.sparing.cloud/static/emoji/2705.png'
      },
      [IconName.Remote]: {
        name: 'Remote',
        url: 'https://mattermost.sparing.cloud/static/emoji/1f3e0.png'
      },
      [IconName.NotAvailable]: {
        name: 'Not available',
        url: 'https://mattermost.sparing.cloud/static/emoji/274c.png'
      },
      [IconName.AbsenceInReview]: {
        name: 'Absence in review',
        url: 'https://mattermost.sparing.cloud/static/emoji/2753.png'
      },
      [IconName.Comment]: {
        name: 'Comment',
        url: 'https://mattermost.sparing.cloud/static/emoji/1f4ac.png'
      },
      [IconName.Thunder]: {
        name: 'Unplanned',
        url: 'https://mattermost.sparing.cloud/static/emoji/26a1.png'
      },
      [IconName.Sick]: {
        name: 'Sick',
        url: 'https://mattermost.sparing.cloud/static/emoji/1f912.png'
      },
      [IconName.Course]: {
        name: 'Course or conference',
        url: 'https://mattermost.sparing.cloud/static/emoji/1f393.png'
      },
      [IconName.HalfDay]: {
        name: 'Half-day',
        url: 'https://mattermost.sparing.cloud/static/emoji/1f317.png'
      }
    }

    const icon = iconMap[props.name as IconName]

    return { icon }
  }
})
</script>

<style lang="scss" scoped>
.img {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
  top: 3px;
  margin-right: 3px;
}
</style>
