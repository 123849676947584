
import commentTeleport from './comment-teleport.vue'
import api from '@/api'
import { CreateDayDto, DayDto, TimelineUserDto } from 'service/__generated-api'
import { defineComponent, PropType, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { showPeriodsInMonthlyView } from '@/state/monthly-view'
import { dayNotFilled, anyUnavailabilityWasMarked } from '@/utils'

export default defineComponent({
  components: { commentTeleport },
  props: {
    day: {
      require: true,
      type: Object as PropType<DayDto>
    },
    user: {
      require: true,
      type: Object as PropType<TimelineUserDto>
    }
  },
  methods: {
    dayNotFilled,
    anyUnavailabilityWasMarked,
    halfDayAbsenceMsg(day: CreateDayDto): string {
      return `\n${day.halfNotAvailableStartTime} - ${day.halfNotAvailableEndTime} + half-day absence`
    }
  },
  setup(props) {
    /**
     * Calc work duration based on day periods
     */
    function calcWorkDuration(periodsStr: string) {
      if (periodsStr.length === 0) return ''

      const periods = periodsStr.split(',')
      const minutesOfWork = periods
        .map(period => {
          const [start, end] = period.split('-')
          const [startHours, startMinutes] = start.split(':').map(Number)
          const [endHours, endMinutes] = end.split(':').map(Number)

          return (endHours - startHours) * 60 + endMinutes - startMinutes
        })
        .reduce((a, b) => a + b, 0)

      let hours = Math.floor(minutesOfWork / 60)
      const minutes = minutesOfWork % 60

      if (minutes >= 20) {
        hours += 0.5
      } else if (minutes >= 40) {
        hours += 1
      }

      return `~${hours}h`
    }

    /**
     * Show info in cell
     */
    const cellInfo = ref('')

    watch(
      showPeriodsInMonthlyView,
      () => {
        if (showPeriodsInMonthlyView.value) {
          cellInfo.value = props.day?.periods.split(',').join(', ') || ''
        } else {
          cellInfo.value = calcWorkDuration(props.day?.periods || '')
        }
      },
      { immediate: true }
    )

    /**
     * Determine if user is the admin
     */
    const isUserAdmin = useStore().state.user.role === 'admin'

    /**
     * Absences acceptation
     */
    const isAbsenceAcceptationLoading = ref(false)
    async function acceptAbsence(day: DayDto) {
      if (!isUserAdmin || isAbsenceAcceptationLoading.value === true) return
      isAbsenceAcceptationLoading.value = true
      await api.days.acceptAbsence({
        formattedDate: day.formattedDate,
        userId: day.userId
      })
      day.notAvailableAccepted = true
      isAbsenceAcceptationLoading.value = false
    }

    async function declineAbsence(day: DayDto) {
      if (!isUserAdmin || isAbsenceAcceptationLoading.value === true) return
      isAbsenceAcceptationLoading.value = true
      const res = await api.days.declineAbsence({
        formattedDate: day.formattedDate,
        userId: day.userId
      })
      Object.assign(day, res.data)
      isAbsenceAcceptationLoading.value = false
    }

    /**
     * Coment visibility
     */
    const isCommentHover = ref(false)

    /**
     * Return
     */
    return {
      isCommentHover,
      cellInfo,
      isUserAdmin,
      acceptAbsence,
      declineAbsence,
      isAbsenceAcceptationLoading
    }
  }
})
