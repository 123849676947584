<template>
  <nav-component />
  <main class="main">
    <router-view v-slot="{ Component }" :key="$route.fullPath">
      <transition name="router-transition" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </main>
  <footer class="app-footer">Sparing Interactive &copy; 2021</footer>
</template>

<script>
import { defineComponent } from 'vue'
import NavComponent from '@/components/nav'

export default defineComponent({
  components: { NavComponent },
  async mounted() {
    await this.$nextTick()

    // Bulma navbar: https://bulma.io/documentation/components/navbar/
    const $navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll('.navbar-burger'),
      0
    )

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
      // Add a click event on each of them
      $navbarBurgers.forEach(el => {
        el.addEventListener('click', () => {
          // Get the target from the "data-target" attribute
          const target = el.dataset.target
          const $target = document.getElementById(target)

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active')
          $target.classList.toggle('is-active')
        })
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.router-transition-enter-active,
.router-transition-leave-active {
  transition: opacity 0.15s ease-in-out;
}

.router-transition-enter-from,
.router-transition-leave-to {
  opacity: 0;
}

.main {
  min-height: calc(100vh - 260px);
}

.app-footer {
  font-size: 14px;
  text-align: center;
  margin: 100px 0 30px;
  color: #aaa;
}
</style>
