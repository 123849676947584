
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { TeamDto } from '../../service/__generated-api'
import api from '@/api'
import { normalizeArray } from '@/utils'

export default defineComponent({
  setup() {
    const dropdown = ref<HTMLDivElement>()
    const dropdownActive = ref(false)

    const route = useRoute()
    const router = useRouter()
    const teamsRoute = computed({
      get() {
        return normalizeArray(route.query.teamsIds)
      },
      set(teamsIds: string[]) {
        router.push({ query: { teamsIds } })
      }
    })

    const teams = ref<TeamDto[]>([])
    async function fetchTeams() {
      teams.value = await api.teams.getTeams().then(({ data }) => data)
    }
    fetchTeams()

    const handleOutsideClick = (event: Event) => {
      if (!dropdown.value) return
      if (dropdown.value.contains(event.target as HTMLElement)) return
      dropdownActive.value = false
    }
    onMounted(() => {
      window.addEventListener('click', handleOutsideClick)
    })
    onUnmounted(() => {
      window.removeEventListener('click', handleOutsideClick)
    })

    return {
      dropdown,
      dropdownActive,
      teamsRoute,
      teams
    }
  }
})
