<template>
  <div>
    <div class="labels">
      <div class="label">From*</div>
      <div class="label pl-4">To*</div>
    </div>

    <smooth-reflow :options="{ property: 'height', transition: 'height 0.2s' }">
      <period-picker
        v-for="period in periods"
        :key="period.id"
        @remove="removePeriod(period.id)"
        :model-value="period"
        @update:model-value="
          updatedPeriod => updatePeriod(period, updatedPeriod)
        "
        :disableDelete="periods.length === 1"
      />
    </smooth-reflow>

    <button type="button" class="button button--add-period" @click="addPeriod">
      Add period
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import PeriodPicker from './period-picker.vue'
import { periodsStringify } from '@/utils'

interface Period {
  id: number
  from: string
  to: string
}

let uid = 0

export default defineComponent({
  components: { PeriodPicker },
  props: {
    modelValue: {
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const periods = ref<Period[]>([])

    const startPeriods = props.modelValue?.split(',').map(period => {
      const [from, to] = period.split('-')
      return {
        from,
        to,
        id: uid++
      }
    }) ?? [
      {
        from: '08:00',
        to: '16:00',
        id: uid++
      }
    ]

    periods.value = startPeriods

    watch(
      periods,
      () => emit('update:modelValue', periodsStringify(periods.value)),
      { deep: true }
    )

    function addPeriod(): void {
      const lastPeriod = periods.value[periods.value.length - 1]

      periods.value.push({
        from: lastPeriod ? lastPeriod.to : '08:00',
        to: lastPeriod ? lastPeriod.to : '16:00',
        id: uid++
      })
    }

    function removePeriod(periodId: number) {
      const index = periods.value.findIndex(period => period.id === periodId)
      periods.value.splice(index, 1)
    }

    function updatePeriod(period: Period, updatedPeriod: Period) {
      period.from = updatedPeriod.from
      period.to = updatedPeriod.to
    }

    return {
      periods,
      addPeriod,
      removePeriod,
      updatePeriod
    }
  }
})
</script>

<style lang="scss" scoped>
.labels {
  max-width: 270px;
  display: flex;
}

.label {
  width: 50%;
}

.button {
  &--add-period {
    width: 100%;
  }
}
</style>
