<template>
  <td
    class="day"
    height="35"
    :class="{ 'day--not-business': !day?.businessDay }"
    @mouseenter="() => (isCommentHover = true)"
    @mouseleave="() => (isCommentHover = false)"
    :title="day?.periods.split(',').join(', ')"
  >
    <span v-if="day && dayNotFilled(day)">
      <icon name="kaktus" />
    </span>
    <span v-else>
      <span v-if="day?.unplannedUnavailability">
        <icon name="not-available" title="Unplanned absence" />
        <icon name="thunder" title="Unplanned absence" />
      </span>
      <span v-else-if="day?.sick">
        <icon name="sick" />
      </span>
      <span v-else-if="day?.atConference">
        <icon name="course" />
      </span>
      <span
        v-else-if="
          day?.notAvailable &&
          (day?.notAvailableAccepted || day?.markedByPartTimeUser)
        "
      >
        <icon name="not-available" />
      </span>
      <span v-if="day?.halfNotAvailable">
        <icon name="half-day" :title="halfDayAbsenceMsg(day)" />
      </span>
      <span
        v-if="
          day?.notAvailable &&
          !day?.notAvailableAccepted &&
          !day?.markedByPartTimeUser
        "
      >
        <span v-if="isUserAdmin" class="absence-admin-view">
          <button
            class="button is-small is-outlined is-danger"
            :class="{ 'is-loading': isAbsenceAcceptationLoading }"
            @click="declineAbsence(day)"
          >
            <span class="icon is-small">
              <i class="fa fa-times"></i>
            </span>
          </button>
          <button
            class="button is-small is-outlined is-success"
            @click="acceptAbsence(day)"
            :class="{ 'is-loading': isAbsenceAcceptationLoading }"
          >
            <span class="icon is-small">
              <i class="fas fa-check"></i>
            </span>
          </button>
        </span>
        <icon name="absence-in-review" />
      </span>

      <span v-else-if="day?.businessDay && !anyUnavailabilityWasMarked(day)">
        <icon :name="day.remote ? 'remote' : 'office'" />
        {{ cellInfo }}
      </span>

      <span v-if="day?.comment || day?.halfNotAvailableStartTime">
        <icon name="comment" v-if="day.comment" />
        <comment-teleport
          v-if="user"
          :user="user"
          :day="day"
          :is-active="isCommentHover"
        />
      </span>
    </span>
  </td>
</template>

<script lang="ts">
import commentTeleport from './comment-teleport.vue'
import api from '@/api'
import { CreateDayDto, DayDto, TimelineUserDto } from 'service/__generated-api'
import { defineComponent, PropType, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { showPeriodsInMonthlyView } from '@/state/monthly-view'
import { dayNotFilled, anyUnavailabilityWasMarked } from '@/utils'

export default defineComponent({
  components: { commentTeleport },
  props: {
    day: {
      require: true,
      type: Object as PropType<DayDto>
    },
    user: {
      require: true,
      type: Object as PropType<TimelineUserDto>
    }
  },
  methods: {
    dayNotFilled,
    anyUnavailabilityWasMarked,
    halfDayAbsenceMsg(day: CreateDayDto): string {
      return `\n${day.halfNotAvailableStartTime} - ${day.halfNotAvailableEndTime} + half-day absence`
    }
  },
  setup(props) {
    /**
     * Calc work duration based on day periods
     */
    function calcWorkDuration(periodsStr: string) {
      if (periodsStr.length === 0) return ''

      const periods = periodsStr.split(',')
      const minutesOfWork = periods
        .map(period => {
          const [start, end] = period.split('-')
          const [startHours, startMinutes] = start.split(':').map(Number)
          const [endHours, endMinutes] = end.split(':').map(Number)

          return (endHours - startHours) * 60 + endMinutes - startMinutes
        })
        .reduce((a, b) => a + b, 0)

      let hours = Math.floor(minutesOfWork / 60)
      const minutes = minutesOfWork % 60

      if (minutes >= 20) {
        hours += 0.5
      } else if (minutes >= 40) {
        hours += 1
      }

      return `~${hours}h`
    }

    /**
     * Show info in cell
     */
    const cellInfo = ref('')

    watch(
      showPeriodsInMonthlyView,
      () => {
        if (showPeriodsInMonthlyView.value) {
          cellInfo.value = props.day?.periods.split(',').join(', ') || ''
        } else {
          cellInfo.value = calcWorkDuration(props.day?.periods || '')
        }
      },
      { immediate: true }
    )

    /**
     * Determine if user is the admin
     */
    const isUserAdmin = useStore().state.user.role === 'admin'

    /**
     * Absences acceptation
     */
    const isAbsenceAcceptationLoading = ref(false)
    async function acceptAbsence(day: DayDto) {
      if (!isUserAdmin || isAbsenceAcceptationLoading.value === true) return
      isAbsenceAcceptationLoading.value = true
      await api.days.acceptAbsence({
        formattedDate: day.formattedDate,
        userId: day.userId
      })
      day.notAvailableAccepted = true
      isAbsenceAcceptationLoading.value = false
    }

    async function declineAbsence(day: DayDto) {
      if (!isUserAdmin || isAbsenceAcceptationLoading.value === true) return
      isAbsenceAcceptationLoading.value = true
      const res = await api.days.declineAbsence({
        formattedDate: day.formattedDate,
        userId: day.userId
      })
      Object.assign(day, res.data)
      isAbsenceAcceptationLoading.value = false
    }

    /**
     * Coment visibility
     */
    const isCommentHover = ref(false)

    /**
     * Return
     */
    return {
      isCommentHover,
      cellInfo,
      isUserAdmin,
      acceptAbsence,
      declineAbsence,
      isAbsenceAcceptationLoading
    }
  }
})
</script>

<style lang="scss" scoped>
.day {
  position: relative;
  font-size: 14px;
  vertical-align: middle;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &--not-business {
    background-color: rgb(230, 228, 228);
  }
}

.absence-admin-view {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;

  display: flex;
  align-items: center;

  transition: opacity 0.15s;
  opacity: 0;

  &:hover {
    opacity: 1;
  }

  .button {
    width: 50%;
    height: 100%;
    border-radius: 0;
  }
}
</style>
