import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from '@/api'
import LoaderCompoentn from '@/components/loader.vue'
import SmoothReflowComponent from '@/components/smooth-reflow.vue'
import Icon from '@/components/icon.vue'
import '@/assets/global.scss'

async function main() {
  if (window.location.search.includes('?token=')) {
    const token = window.location.search.split('=')[1]
    window.localStorage.setItem('token', token)
  }

  try {
    const res = await api.users.getCurrent()
    store.commit('user/login')
    store.commit('user/setInfo', res.data)
  } catch (err) {
    if (!err.response) throw new Error(err)
  }

  createApp(App)
    .use(store)
    .use(router)
    .component('loader', LoaderCompoentn)
    .component('smooth-reflow', SmoothReflowComponent)
    .component('icon', Icon)
    .mount('#app')
}

main()
