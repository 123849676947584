<template>
  <div class="container">
    <div class="section">
      <h1 class="title">My settings</h1>
      <p>Set up Your mattermost ID to revice notifications!</p>
      <hr />

      <form @submit.prevent="submit" class="form">
        <label for="mattermost-id" class="label">Mattermost ID</label>
        <input
          id="mattermost-id"
          class="input"
          type="text"
          v-model="mattermostId"
          placeholder="ig99fbynuby17cyiwa4g52wn8a"
          minlength="26"
          maxlength="26"
        />

        <div class="field is-grouped mt-3">
          <div class="control">
            <button
              class="button is-link"
              :class="{ 'is-loading': isMattermostIdLoading }"
              type="submit"
            >
              Save
            </button>
          </div>
          <div class="control" v-if="store.state.user.mattermostId.length > 0">
            <button
              class="button is-link is-light"
              :class="{ 'is-loading': isMattermostTestMessageLoading }"
              type="button"
              @click="mattermostTestMessage"
            >
              Test message
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="section">
      <h2 class="subtitle">How to get my Mattermost ID?</h2>
      <ol>
        <li>
          Go to
          <a
            class="link"
            href="https://mattermost.sparing.cloud/"
            target="_blank"
          >
            mattermost.sparing.cloud
            <span class="icon">
              <i class="fa fa-external-link-alt"></i>
            </span>
          </a>
        </li>
        <li>Press <b>F12</b> on your keyboard</li>
        <li>Paste code below to console and hit <b>Enter</b></li>
      </ol>
      <div class="pre">
        fetch("https://mattermost.sparing.cloud/api/v4/users/me").then(a=>a.json()).then(a=>{console.log(`\nYour
        id:\n\n%c${a.id}\n\n`,"font-size:24px")})
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import api from '@/api'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const store = useStore()
    const mattermostId = ref<string>(store.state.user.mattermostId ?? '')

    const isMattermostIdLoading = ref(false)
    async function submit() {
      if (isMattermostIdLoading.value === true) return
      isMattermostIdLoading.value = true

      const res = await api.users.updateMattermostId({
        mattermostId: mattermostId.value
      })

      store.commit('user/setInfo', res.data)
      isMattermostIdLoading.value = false
    }

    const isMattermostTestMessageLoading = ref(false)
    async function mattermostTestMessage() {
      if (isMattermostIdLoading.value === true) return
      isMattermostTestMessageLoading.value = true
      await api.mattermost.testMessage()
      isMattermostTestMessageLoading.value = false
    }

    return {
      mattermostId,
      isMattermostIdLoading,
      submit,
      mattermostTestMessage,
      isMattermostTestMessageLoading,
      store
    }
  }
})
</script>

<style lang="scss" scoped>
.form {
  max-width: 450px;
}
.pre {
  font-family: monospace;
  font-size: 12px;
  background-color: #eee;
  border: 1px solid #ddd;
  padding: 15px;
  margin: 10px 0;
  width: 450px;
  word-break: break-word;
}
</style>
