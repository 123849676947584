
import { defineComponent, PropType, ref } from 'vue'
import { DayTemplateDto } from 'service/__generated-api'
import api from '@/api'

export default defineComponent({
  props: {
    dayTemplate: {
      type: Object as PropType<DayTemplateDto>,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  emits: ['remove'],
  setup(props, { emit }) {
    const isDeleting = ref(false)

    async function removeTemplate() {
      if (isDeleting.value === true) return

      try {
        await api.dayTemplates.delete(props.dayTemplate.id)
        emit('remove', props.dayTemplate.id)
      } catch (err) {
        console.error(err)
      }

      isDeleting.value = false
    }

    const editLink = `/my-templates/edit/${props.dayTemplate.id}`
    const periods = props.dayTemplate.periods.split(',')

    return { removeTemplate, editLink, isDeleting, periods }
  }
})
