<template>
  <div class="container">
    <div class="section">
      <div class="columns">
        <div class="column">
          <h1 class="title">Daily availability</h1>
        </div>
        <div class="column is-narrow">
          <teams-dropdown />
        </div>
      </div>
      <hr />
      <team-daily-calendar />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TeamDailyCalendar from '@/components/calendars/team-daily.vue'
import TeamsDropdown from '@/components/teams-dropdown.vue'
export default defineComponent({
  components: { TeamsDropdown, TeamDailyCalendar }
})
</script>

<style lang="scss" scoped></style>
