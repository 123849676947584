
import { isAfter, parse as parseDate } from 'date-fns'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  emits: ['remove', 'update:modelValue'],
  props: ['modelValue', 'disableDelete'],
  setup(props, { emit }) {
    const fromInput = ref<HTMLInputElement>()
    const toInput = ref<HTMLInputElement>()
    const hasFromError = ref(false)
    const hasToError = ref(false)
    const formatRegex = /^\d\d:\d\d$/

    watch(
      () => [props.modelValue.from, props.modelValue.to],
      () => {
        hasFromError.value = false
        hasToError.value = false

        const { from, to } = props.modelValue

        if (!formatRegex.test(from)) {
          hasFromError.value = true
        }

        if (!formatRegex.test(to)) {
          hasToError.value = true
        }

        const fromDate = parseDate(from, 'HH:mm', new Date())
        const toDate = parseDate(to, 'HH:mm', new Date())

        const isPeriodError = !isAfter(toDate, fromDate)

        toInput.value?.setCustomValidity(
          isPeriodError ? '"To" must be after "From"' : ''
        )

        if (isPeriodError) {
          hasToError.value = true
          hasFromError.value = true
        }
      }
    )

    return { emit, hasFromError, hasToError, fromInput, toInput }
  }
})
