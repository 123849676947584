<template>
  <tr class="row">
    <user-in-table :user="user" />
    <td
      :colspan="hours.length"
      class="timeline-row"
      ref="timelineEl"
      @mouseenter="() => (isCommentHover = true)"
      @mouseleave="() => (isCommentHover = false)"
    >
      <div class="icons">
        <span v-if="day && dayNotFilled(day)">
          <icon name="kaktus" />
        </span>
        <span v-else>
          <span v-if="day?.unplannedUnavailability">
            <icon name="not-available" title="Unplanned absence" />
            <icon name="thunder" title="Unplanned absence" />
          </span>
          <span v-else-if="day?.sick">
            <icon name="sick" />
          </span>
          <span v-else-if="day?.atConference">
            <icon name="course" />
          </span>
          <span v-else-if="day?.notAvailable && day?.notAvailableAccepted">
            <icon name="not-available" />
          </span>
          <span v-if="day?.halfNotAvailable">
            <icon name="half-day" :title="halfDayAbsenceMsg(day)" />
          </span>
          <span v-if="day?.notAvailable && !day?.notAvailableAccepted">
            <icon name="absence-in-review" />
          </span>
          <span
            v-else-if="day?.businessDay && !anyUnavailabilityWasMarked(day)"
          >
            <icon :name="day.remote ? 'remote' : 'office'" />
          </span>
        </span>

        <span v-if="day?.comment || day?.halfNotAvailableStartTime">
          <icon name="comment" v-if="day.comment" />
          <comment-teleport
            :user="user"
            :day="day"
            :is-active="isCommentHover"
          />
        </span>
      </div>
      <div class="hours">
        <div v-for="hour in hours" :key="hour" class="hour"></div>
      </div>
      <div
        v-for="{ left, width, title, remote } in periodsToRender"
        :key="left + width"
        class="period"
        :class="{ 'period--remote': remote }"
        :style="{ left, width }"
        v-text="title"
      />
    </td>
  </tr>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref
} from 'vue'
import { CreateDayDto, TimelineUserDto } from '@/../service/__generated-api'
import { debounce } from 'ts-debounce'
import UserInTable from '@/components/user-in-table.vue'
import CommentTeleport from '@/components/calendars/comment-teleport.vue'
import { anyUnavailabilityWasMarked, dayNotFilled } from '@/utils'

export default defineComponent({
  components: { UserInTable, CommentTeleport },
  props: {
    user: {
      type: Object as PropType<TimelineUserDto>,
      required: true
    },
    hours: {
      type: Array as PropType<number[]>,
      required: true
    }
  },
  methods: {
    dayNotFilled,
    anyUnavailabilityWasMarked,
    halfDayAbsenceMsg(day: CreateDayDto): string {
      return `\n${day.halfNotAvailableStartTime} - ${day.halfNotAvailableEndTime} + half-day absence`
    }
  },
  setup(props) {
    const timelineEl = ref<HTMLTableRowElement>()
    const periodsToRender = ref<{ left: string; width: string }[]>([])

    const stringArrayPeriods = props.user.days[0]?.periods?.length // User not available
      ? props.user.days[0].periods.split(',')
      : []

    const periods = stringArrayPeriods.map(period => {
      const [startTime, endTime] = period.split('-')
      const [startHour, startMinutes] = startTime.split(':').map(Number)
      const [endHour, endMinutes] = endTime.split(':').map(Number)

      const start = startHour + startMinutes / 60
      const end = endHour + endMinutes / 60

      return { start, end, period }
    })

    function renderPeriods() {
      if (!periods) return // KAKUTS

      const hourWidth =
        (timelineEl.value?.clientWidth ?? 0) / props.hours.length

      periodsToRender.value = periods.map(period => {
        const width = `${(period.end - period.start) * hourWidth + 1}px`
        const left = `${(period.start - props.hours[0]) * hourWidth}px`
        const title = period.period
        const remote = props.user.days[0].remote

        return { left, width, title, remote }
      })
    }

    onMounted(renderPeriods)

    const debouncedRenderPeriods = debounce(renderPeriods, 200)
    window.addEventListener('resize', debouncedRenderPeriods)
    onBeforeUnmount(() => {
      window.removeEventListener('resize', debouncedRenderPeriods)
    })

    const isCommentHover = ref(false)
    const day = computed(() => props.user.days[0])

    return {
      day,
      isCommentHover,
      timelineEl,
      periodsToRender
    }
  }
})
</script>

<style lang="scss" scoped>
.user {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.image {
  display: inline-block !important;
  position: relative;
  top: 4px;
  margin-right: 3px;

  width: 20px;
  height: 20px;
}

.timeline-row {
  position: relative;
  padding: 0 !important;
}

.hours {
  display: flex;
  height: 35px;
}

.hour {
  width: 100%;
  height: 100%;
  border-right: 1px solid #dbdbdb;

  &:last-child {
    border: none;
  }
}

.period {
  position: absolute;
  height: 20px;

  top: 7px;

  font-size: 14px;
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  background-color: #7ce24d;

  &--remote {
    background-color: #68ccf3;
  }
}

.icons {
  position: absolute;
  top: 3px;
  left: 5px;
}
</style>
