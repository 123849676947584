
import {
  format,
  add,
  startOfMonth,
  parse,
  eachDayOfInterval,
  endOfMonth
} from 'date-fns'
import { computed, defineComponent, nextTick, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { formatDate, normalizeArray } from '@/utils'
import { useApi } from '@/api'
import { showPeriodsInMonthlyView } from '@/state/monthly-view'
import TimeSwitcher from '@/components/time-switcher.vue'
import UserRow from '@/components/calendars/team-monthly-user-row.vue'
import UserInTable from '@/components/user-in-table.vue'

const USER_LABEL_WIDTH = 220

export default defineComponent({
  components: { TimeSwitcher, UserRow, UserInTable },
  setup() {
    /**
     * Day width
     */
    const dayWidth = computed(() =>
      showPeriodsInMonthlyView.value ? 120 : 100
    )

    /**
     * Current month state
     */
    const route = useRoute()
    const routeMonth = `${route.params.formattedDate}`
    const currentDate = ref(
      /^\d{4}-\d\d-\d\d$/.test(routeMonth)
        ? parse(routeMonth, 'yyyy-MM-dd', new Date())
        : new Date()
    )
    const routeIds = route.query.teamsIds
    const teamsIds = normalizeArray(routeIds).map(Number)

    /**
     * Routing
     */
    const router = useRouter()
    function nextMonth() {
      router.push({
        ...route,
        params: {
          formattedDate: formatDate(
            startOfMonth(add(currentDate.value, { months: 1 }))
          )
        }
      })
    }

    function previousMonth() {
      router.push({
        ...route,
        params: {
          formattedDate: formatDate(
            endOfMonth(add(currentDate.value, { months: -1 }))
          )
        }
      })
    }

    /**
     * Month days
     */
    const firstDay = startOfMonth(currentDate.value)
    const lastDay = add(firstDay, { months: 1, days: -1 })
    const interval = { start: firstDay, end: lastDay }
    const monthDaysList = ref(eachDayOfInterval(interval))

    /**
     * Api call
     */
    const usersRes = useApi(api =>
      api.timeline.monthly(formatDate(currentDate.value), { teamsIds })
    )
    const isLoading = usersRes.isLoading
    const users = usersRes.data

    /**
     * Scroll view to current date
     */
    const tableContainer = ref<HTMLDivElement>()
    const dayOfTheMonth = parseInt(format(currentDate.value, 'd'))
    const scrollShift = (dayOfTheMonth - 2) * dayWidth.value

    watch(isLoading, async () => {
      await nextTick()
      if (tableContainer.value) {
        tableContainer.value.scrollLeft = scrollShift
      }
    })

    /**
     * Table width
     */
    const tableStyle = computed(() => {
      return {
        width: `${
          USER_LABEL_WIDTH + monthDaysList.value.length * dayWidth.value
        }px`
      }
    })

    /**
     * Return
     */
    return {
      currentDate,
      format,
      nextMonth,
      previousMonth,
      isLoading,
      monthDaysList,
      users,
      tableContainer,
      tableStyle,
      dayWidth,
      USER_LABEL_WIDTH,
      showPeriodsInMonthlyView
    }
  }
})
