export interface UserDto {
  /** @example 4 */
  id: number

  /** @example Joe */
  firstName: string

  /** @example Doe */
  lastName: string

  /** @example user */
  role: string

  /**
   * Google avatar url
   * @example https://lh3.googleusercontent.com/-fg4b8rnovbY/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucndunZbm5zWpI4-Lu8G_6SxDIZWgA/s96-c/photo.jpg
   */
  picture: string

  /** @example false */
  worksPartTime: boolean
}

export interface DayTemplateDto {
  /** @example 08:00-14:00,16:00-17:30 */
  periods: string

  /** @example Default Day */
  name: string
  comment?: string
  remote: boolean
  notAvailable: boolean

  /** @example 1 */
  id: number
}

export interface CurrentUserDto {
  /** @example 4 */
  id: number

  /** @example Joe */
  firstName: string

  /** @example Doe */
  lastName: string

  /** @example user */
  role: string

  /**
   * Google avatar url
   * @example https://lh3.googleusercontent.com/-fg4b8rnovbY/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucndunZbm5zWpI4-Lu8G_6SxDIZWgA/s96-c/photo.jpg
   */
  picture: string

  /** @example false */
  worksPartTime: boolean
  dayTemplates: DayTemplateDto[]
  mattermostId: string
}

export interface MattermostIdDto {
  mattermostId: string
}

export interface UpdatePartTimeInfo {
  worksPartTime: boolean
}

export interface CreateUserDto {
  id: number
  firstName: string
  lastName: string
  email: string
  picture: string
  accessToken: string
  refreshToken: string
}

export interface UpdateTokenDto {
  userId: number
  token: string
}

export interface CreateDayTemplateDto {
  /** @example 08:00-14:00,16:00-17:30 */
  periods: string

  /** @example Default Day */
  name: string
  comment?: string
  remote: boolean
  notAvailable: boolean
}

export interface UpdateDayTemplateDto {
  /** @example 08:00-14:00,16:00-17:30 */
  periods: string

  /** @example Default Day */
  name: string
  comment?: string
  remote: boolean
  notAvailable: boolean
}

export interface DayDto {
  formattedDate: string
  periods: string
  comment?: string
  remote: boolean
  notAvailable: boolean
  notAvailableAccepted?: boolean
  halfNotAvailable?: boolean
  unplannedUnavailability?: boolean
  makingUpUnavailability?: boolean
  atConference?: boolean
  markedByPartTimeUser?: boolean
  sick?: boolean
  halfNotAvailableStartTime?: string
  halfNotAvailableEndTime?: string

  /** @example 41 */
  userId: number
  businessDay: boolean
  monthYearUser: string
}

export interface CreateDayDto {
  formattedDate: string
  periods: string
  comment?: string
  remote: boolean
  notAvailable: boolean
  notAvailableAccepted?: boolean
  halfNotAvailable?: boolean
  unplannedUnavailability?: boolean
  makingUpUnavailability?: boolean
  atConference?: boolean
  markedByPartTimeUser?: boolean
  sick?: boolean
  halfNotAvailableStartTime?: string
  halfNotAvailableEndTime?: string
}

export interface DeleteDayDto {
  formattedDate: string
}

export interface AbsenceAcceptationDto {
  formattedDate: string
  userId: number
}

export interface TestMessageDto {
  success: boolean
}

export interface ServerSettingsDto {
  mattermostApiUrl: string
  mattermostApiAccessToken: string
  mattermostBotId: string
  mattermostInfoChannelId: string
  mattermostDailyChannelId: string
  webAppUrl: string
}

export interface TimelineUserDto {
  /** @example 4 */
  id: number

  /** @example Joe */
  firstName: string

  /** @example Doe */
  lastName: string

  /** @example user */
  role: string

  /**
   * Google avatar url
   * @example https://lh3.googleusercontent.com/-fg4b8rnovbY/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucndunZbm5zWpI4-Lu8G_6SxDIZWgA/s96-c/photo.jpg
   */
  picture: string

  /** @example false */
  worksPartTime: boolean
  days: DayDto[]
}

export interface TeamDto {
  /** @example 1 */
  id: number
  name: string
  users: UserDto[]
}

export interface CreateTeamDto {
  name: string
  users: number[]
}

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  ResponseType
} from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded'
}

export class HttpClient<SecurityDataType = unknown> {
  private instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean

  constructor({
    securityWorker,
    secure,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || ''
    })
    this.secure = secure
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  private mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  public request = async <T = any, E = any>({
    secure,
    path,
    type,
    query,
    format = 'json',
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData
          ? { 'Content-Type': type }
          : {}),
        ...(requestParams.headers || {})
      },
      params: query,
      responseType: format,
      data: body,
      url: path
    })
  }
}

/**
 * @title Sparing Timer
 * @version 1.0
 * @contact
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  google = {
    /**
     * No description
     *
     * @tags Auth
     * @name GoogleControllerGoogleAuth
     * @request GET:/google
     */
    googleControllerGoogleAuth: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/google`,
        method: 'GET',
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name GoogleControllerGoogleAuthRedirect
     * @request GET:/google/redirect
     */
    googleControllerGoogleAuthRedirect: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/google/redirect`,
        method: 'GET',
        ...params
      })
  }
  users = {
    /**
     * @description Get all users
     *
     * @tags User
     * @name GetAll
     * @request GET:/users
     * @secure
     */
    getAll: (params: RequestParams = {}) =>
      this.request<UserDto[], void>({
        path: `/users`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags User
     * @name Create
     * @request POST:/users
     * @secure
     */
    create: (data: CreateUserDto, params: RequestParams = {}) =>
      this.request<UserDto, void>({
        path: `/users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Get current user info
     *
     * @tags User
     * @name GetCurrent
     * @request GET:/users/me
     * @secure
     */
    getCurrent: (params: RequestParams = {}) =>
      this.request<CurrentUserDto, void>({
        path: `/users/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Find user by id
     *
     * @tags User
     * @name GetById
     * @request GET:/users/{id}
     * @secure
     */
    getById: (id: number, params: RequestParams = {}) =>
      this.request<UserDto, void>({
        path: `/users/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags User
     * @name Delete
     * @request DELETE:/users/{id}
     * @secure
     */
    delete: (id: number, params: RequestParams = {}) =>
      this.request<UserDto, void>({
        path: `/users/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Upate current user Mattermost id
     *
     * @tags User
     * @name UpdateMattermostId
     * @request POST:/users/mattermost-id
     * @secure
     */
    updateMattermostId: (data: MattermostIdDto, params: RequestParams = {}) =>
      this.request<CurrentUserDto, void>({
        path: `/users/mattermost-id`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags User
     * @name GrantAdmin
     * @request PUT:/users/grant-admin/{id}
     * @secure
     */
    grantAdmin: (id: number, params: RequestParams = {}) =>
      this.request<UserDto, void>({
        path: `/users/grant-admin/${id}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags User
     * @name RevokeAdmin
     * @request PUT:/users/revoke-admin/{id}
     * @secure
     */
    revokeAdmin: (id: number, params: RequestParams = {}) =>
      this.request<UserDto, void>({
        path: `/users/revoke-admin/${id}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags User
     * @name SetPartTimeJob
     * @request PUT:/users/set-part-time-job/{id}
     * @secure
     */
    setPartTimeJob: (
      id: number,
      data: UpdatePartTimeInfo,
      params: RequestParams = {}
    ) =>
      this.request<UserDto, void>({
        path: `/users/set-part-time-job/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags User
     * @name UpdateToken
     * @request PUT:/users/update-token
     * @secure
     */
    updateToken: (data: UpdateTokenDto, params: RequestParams = {}) =>
      this.request<UserDto, void>({
        path: `/users/update-token`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  dayTemplates = {
    /**
     * @description Get all day templates of current user
     *
     * @tags Day Template
     * @name MyTempaltes
     * @request GET:/day-templates
     * @secure
     */
    myTempaltes: (params: RequestParams = {}) =>
      this.request<DayTemplateDto[], void>({
        path: `/day-templates`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Add Day Template
     *
     * @tags Day Template
     * @name Add
     * @request POST:/day-templates
     * @secure
     */
    add: (data: CreateDayTemplateDto, params: RequestParams = {}) =>
      this.request<DayTemplateDto, void>({
        path: `/day-templates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Get Day Template by Id
     *
     * @tags Day Template
     * @name GetById
     * @request GET:/day-templates/{id}
     * @secure
     */
    getById: (id: number, params: RequestParams = {}) =>
      this.request<DayTemplateDto, void>({
        path: `/day-templates/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Update Day Template
     *
     * @tags Day Template
     * @name Update
     * @request PUT:/day-templates/{id}
     * @secure
     */
    update: (
      id: number,
      data: UpdateDayTemplateDto,
      params: RequestParams = {}
    ) =>
      this.request<DayTemplateDto, void>({
        path: `/day-templates/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Delete Day Template by id
     *
     * @tags Day Template
     * @name Delete
     * @request DELETE:/day-templates/{id}
     * @secure
     */
    delete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/day-templates/${id}`,
        method: 'DELETE',
        secure: true,
        ...params
      })
  }
  days = {
    /**
     * @description Get current user days from the specified range (rounded to whole months)
     *
     * @tags Day
     * @name GetDays
     * @request GET:/days/days/{formattedStartDate}/{formattedEndDate}
     * @secure
     */
    getDays: (
      formattedStartDate: string,
      formattedEndDate: string,
      params: RequestParams = {}
    ) =>
      this.request<DayDto[], void>({
        path: `/days/days/${formattedStartDate}/${formattedEndDate}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Add or update days
     *
     * @tags Day
     * @name UpsertMany
     * @request POST:/days/upsert-many
     * @secure
     */
    upsertMany: (data: CreateDayDto[], params: RequestParams = {}) =>
      this.request<DayDto[], void>({
        path: `/days/upsert-many`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Delete many days
     *
     * @tags Day
     * @name DeleteMany
     * @request DELETE:/days/delete-many
     * @secure
     */
    deleteMany: (data: DeleteDayDto[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/days/delete-many`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Day
     * @name AcceptAbsence
     * @request POST:/days/accept-absence
     * @secure
     */
    acceptAbsence: (data: AbsenceAcceptationDto, params: RequestParams = {}) =>
      this.request<DayDto, void>({
        path: `/days/accept-absence`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Day
     * @name DeclineAbsence
     * @request POST:/days/decline-absence
     * @secure
     */
    declineAbsence: (data: AbsenceAcceptationDto, params: RequestParams = {}) =>
      this.request<DayDto, void>({
        path: `/days/decline-absence`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  mattermost = {
    /**
     * @description Send test message to current user mattermost account
     *
     * @tags Mattermost integration
     * @name TestMessage
     * @request POST:/mattermost/test-message
     * @secure
     */
    testMessage: (params: RequestParams = {}) =>
      this.request<TestMessageDto, void>({
        path: `/mattermost/test-message`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      })
  }
  settings = {
    /**
     * @description Get server settings
     *
     * @tags Server settings
     * @name Get
     * @request GET:/settings
     * @secure
     */
    get: (params: RequestParams = {}) =>
      this.request<ServerSettingsDto, void>({
        path: `/settings`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Update server settings
     *
     * @tags Server settings
     * @name Update
     * @request PUT:/settings
     * @secure
     */
    update: (data: ServerSettingsDto, params: RequestParams = {}) =>
      this.request<ServerSettingsDto, void>({
        path: `/settings`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  timeline = {
    /**
     * @description Get all users and theirs selected day
     *
     * @tags Timeline
     * @name Daily
     * @request GET:/timeline/daily/{formattedDate}
     * @secure
     */
    daily: (
      formattedDate: string,
      query?: { teamsIds?: number | number[] },
      params: RequestParams = {}
    ) =>
      this.request<TimelineUserDto[], void>({
        path: `/timeline/daily/${formattedDate}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Get all users and theirs selected month
     *
     * @tags Timeline
     * @name Monthly
     * @request GET:/timeline/monthly/{formattedDate}
     * @secure
     */
    monthly: (
      formattedDate: string,
      query?: { teamsIds?: number | number[] },
      params: RequestParams = {}
    ) =>
      this.request<TimelineUserDto[], void>({
        path: `/timeline/monthly/${formattedDate}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Get all users and theirs absences by year
     *
     * @tags Timeline
     * @name Absences
     * @request GET:/timeline/absences-report/{formattedDate}
     * @secure
     */
    absences: (formattedDate: string, params: RequestParams = {}) =>
      this.request<TimelineUserDto[], void>({
        path: `/timeline/absences-report/${formattedDate}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Send daily status test message
     *
     * @tags Timeline
     * @name DailyStatusTestMessage
     * @request POST:/timeline/daily-status-test-message
     * @secure
     */
    dailyStatusTestMessage: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/timeline/daily-status-test-message`,
        method: 'POST',
        secure: true,
        ...params
      })
  }
  teams = {
    /**
     * No description
     *
     * @tags Teams
     * @name GetTeams
     * @request GET:/teams
     * @secure
     */
    getTeams: (
      query?: { teamsIds?: number | number[] },
      params: RequestParams = {}
    ) =>
      this.request<TeamDto[], void>({
        path: `/teams`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Teams
     * @name CreateTeam
     * @request POST:/teams
     * @secure
     */
    createTeam: (data: CreateTeamDto, params: RequestParams = {}) =>
      this.request<TeamDto, void>({
        path: `/teams`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Teams
     * @name GetTeam
     * @request GET:/teams/{teamId}
     * @secure
     */
    getTeam: (teamId: number, params: RequestParams = {}) =>
      this.request<TeamDto, void>({
        path: `/teams/${teamId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Teams
     * @name UpdateTeam
     * @request PATCH:/teams/{teamId}
     * @secure
     */
    updateTeam: (
      teamId: number,
      data: CreateTeamDto,
      params: RequestParams = {}
    ) =>
      this.request<TeamDto, void>({
        path: `/teams/${teamId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Teams
     * @name RemoveTeam
     * @request DELETE:/teams/{teamId}
     * @secure
     */
    removeTeam: (teamId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/teams/${teamId}`,
        method: 'DELETE',
        secure: true,
        ...params
      })
  }
}

export const api = new Api()
