<template>
  <div class="time-swticher">
    <button class="button" @click="emit('prev')">
      <span class="icon">
        <i class="fa fa-chevron-left"></i>
      </span>
    </button>
    <span class="date">
      <slot />
    </span>
    <button class="button" @click="emit('next')">
      <span class="icon">
        <i class="fa fa-chevron-right"></i>
      </span>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  emits: ['prev', 'next'],
  setup(prop, { emit }) {
    return { emit }
  }
})
</script>

<style lang="scss" scoped>
.time-swticher {
  text-align: center;
  margin-bottom: 30px;
}

:deep() {
  .date {
    width: 150px;
    font-size: 20px;
    display: inline-block;
    padding: 4px 10px;
    font-weight: 700;
    line-height: 1em;
  }

  .subdate {
    font-size: 16px;
    font-weight: normal;
  }
}
</style>
