
import { defineComponent, PropType, ref, watch } from 'vue'
import { DayTemplateDto } from 'service/__generated-api'
import PeriodsPicker from '@/components/periods-picker.vue'

export default defineComponent({
  components: { PeriodsPicker },
  props: {
    dayTemplate: {
      required: true,
      type: Object as PropType<DayTemplateDto>
    },
    submitText: String,
    cancelText: String,
    isSubmitLoading: Boolean,
    withTemplateName: {
      type: Boolean,
      default: false
    }
  },
  emits: ['submit'],
  setup(props, { emit }) {
    const templateName = ref(props.dayTemplate?.name ?? '')
    const periods = ref(props.dayTemplate?.periods || '08:00-16:00')
    const remote = ref(props.dayTemplate?.remote || false)
    const comment = ref(props.dayTemplate?.comment || '')

    function onSubmit() {
      emit('submit', {
        name: templateName.value,
        periods: periods.value,
        remote: remote.value,
        comment: comment.value
      })
    }

    watch(props.dayTemplate, () => {
      remote.value = props.dayTemplate.remote
      periods.value = props.dayTemplate.periods
      comment.value = props.dayTemplate.comment || ''
    })

    return { templateName, onSubmit, periods, remote, comment }
  }
})
