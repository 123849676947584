<template>
  <div
    ref="dropdown"
    class="dropdown is-right"
    :class="{ 'is-active': dropdownActive }"
    v-if="teams.length"
  >
    <div class="dropdown-trigger">
      <button
        class="button"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        @click="dropdownActive = !dropdownActive"
      >
        <span>Teams</span>
        <span class="icon is-small">
          <i class="fas fa-filter" aria-hidden="true"></i>
        </span>
      </button>
    </div>
    <div class="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <label
          class="checkbox py-2 px-4"
          v-for="(team, index) in teams"
          :key="team.id"
          style="white-space: nowrap; width: 100%"
          :style="{ 'border-top': index === 0 ? 'none' : '1px solid #dbdbdb' }"
        >
          <input
            type="checkbox"
            name="teamsRoute"
            :value="team.id"
            v-model="teamsRoute"
          />
          {{ team.name }}
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { TeamDto } from '../../service/__generated-api'
import api from '@/api'
import { normalizeArray } from '@/utils'

export default defineComponent({
  setup() {
    const dropdown = ref<HTMLDivElement>()
    const dropdownActive = ref(false)

    const route = useRoute()
    const router = useRouter()
    const teamsRoute = computed({
      get() {
        return normalizeArray(route.query.teamsIds)
      },
      set(teamsIds: string[]) {
        router.push({ query: { teamsIds } })
      }
    })

    const teams = ref<TeamDto[]>([])
    async function fetchTeams() {
      teams.value = await api.teams.getTeams().then(({ data }) => data)
    }
    fetchTeams()

    const handleOutsideClick = (event: Event) => {
      if (!dropdown.value) return
      if (dropdown.value.contains(event.target as HTMLElement)) return
      dropdownActive.value = false
    }
    onMounted(() => {
      window.addEventListener('click', handleOutsideClick)
    })
    onUnmounted(() => {
      window.removeEventListener('click', handleOutsideClick)
    })

    return {
      dropdown,
      dropdownActive,
      teamsRoute,
      teams
    }
  }
})
</script>
