
import api from '@/api'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const store = useStore()
    const mattermostId = ref<string>(store.state.user.mattermostId ?? '')

    const isMattermostIdLoading = ref(false)
    async function submit() {
      if (isMattermostIdLoading.value === true) return
      isMattermostIdLoading.value = true

      const res = await api.users.updateMattermostId({
        mattermostId: mattermostId.value
      })

      store.commit('user/setInfo', res.data)
      isMattermostIdLoading.value = false
    }

    const isMattermostTestMessageLoading = ref(false)
    async function mattermostTestMessage() {
      if (isMattermostIdLoading.value === true) return
      isMattermostTestMessageLoading.value = true
      await api.mattermost.testMessage()
      isMattermostTestMessageLoading.value = false
    }

    return {
      mattermostId,
      isMattermostIdLoading,
      submit,
      mattermostTestMessage,
      isMattermostTestMessageLoading,
      store
    }
  }
})
