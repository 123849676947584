
import { DayDto, TimelineUserDto } from 'service/__generated-api'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    user: {
      required: true,
      type: Object as PropType<TimelineUserDto>
    },
    day: {
      required: true,
      type: Object as PropType<DayDto>
    },
    isActive: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    halfDaysMsg(): string {
      return this.day?.halfNotAvailableStartTime
        ? `${this.day?.halfNotAvailableStartTime} - ${this.day?.halfNotAvailableEndTime} + half-day absence`
        : ''
    },
    actionInfo(): string {
      return this.day.halfNotAvailableStartTime && !this.day.comment
        ? ' is absent half-day on '
        : 'comments'
    }
  }
})
