<template>
  <div>
    <div class="tabs is-centered is-boxed mt-5">
      <ul>
        <li :class="{ 'is-active': activeTab === MyWorkTab.Template }">
          <a @click.prevent="emit('active-tab-change', MyWorkTab.Template)">
            Template
          </a>
        </li>
        <li :class="{ 'is-active': activeTab === MyWorkTab.Custom }">
          <a @click.prevent="emit('active-tab-change', MyWorkTab.Custom)">
            Custom Hours
          </a>
        </li>
        <li :class="{ 'is-active': activeTab === MyWorkTab.NotAvailable }">
          <a @click.prevent="emit('active-tab-change', MyWorkTab.NotAvailable)">
            Not available
          </a>
        </li>
        <li :class="{ 'is-active': activeTab === MyWorkTab.Remove }">
          <a @click.prevent="emit('active-tab-change', MyWorkTab.Remove)">
            Remove
          </a>
        </li>
      </ul>
    </div>

    <div class="mb-5">
      Selected day{{ selectedDays.length > 1 ? 's' : '' }}:
      {{ selectedDays.join(', ') }}
    </div>

    <div class="methods-wrap">
      <day-template-form
        v-if="activeTab === MyWorkTab.Custom"
        :submit-text="'Apply'"
        @submit="day => emit('apply-to-selection', day)"
        :day-template="selectedDay"
      />

      <select-day-template
        v-if="activeTab === MyWorkTab.Template"
        submit-text="Apply"
        @submit="day => emit('apply-to-selection', day)"
      />

      <div v-if="activeTab === MyWorkTab.NotAvailable">
        <div class="box not-available-box">
          <div class="mb-3 comment-wrap">
            <div class="select is-size-6 mb-2">
              <select
                class="select-input"
                v-model="selectedAbsenceType"
                @change="onAbsenceTypeChange($event.target.value)"
              >
                <option
                  v-for="{ key, name } in absenceOptions"
                  :key="key"
                  :value="key"
                >
                  {{ name }}
                </option>
              </select>
            </div>
            <div
              class="work-part-time-check"
              v-if="isUserPartTimeEmployee && notAvailable"
            >
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="markedByPartTimeUser"
                  @change="adjustChecksDueToPartTimeJob"
                />
                I work part-time ⌚️
              </label>
              <div v-if="markedByPartTimeUser">
                <p class="make-up-absence-info">
                  Do you work part-time ? Freely mark your absence, it won't be
                  perceived as a holiday.
                </p>
              </div>
            </div>
            <div
              v-if="
                (notAvailable || unplannedUnavailability || atConference) &&
                !markedByPartTimeUser
              "
            >
              <div class="half-day-check">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    v-model="halfNotAvailable"
                    @click="setDefaultTime()"
                  />
                  Half-day absence 🌗
                </label>
              </div>
              <div v-if="halfNotAvailable" class="half-day-time">
                <p class="half-day-absence-info">
                  Choose your <strong>working hours' 4h slot</strong> when
                  you're gonna be <strong>available</strong>. <br />
                  Remaining 4 hours will be your half-day absence.
                </p>
                <div class="labels">
                  <span class="label">From <sup>*</sup> </span>
                  <span class="label">To <sup>*</sup> </span>
                </div>
                <div class="field is-horizontal">
                  <div class="field mr-4 mb-0">
                    <input
                      class="input"
                      v-model="halfNotAvailableStartTime"
                      pattern="^\d\d:\d\d$"
                      placeholder="10:00"
                      @input="checkTimesAreValidAndSet4hStep"
                      :class="{ 'is-danger': timesInvalidity }"
                    />
                  </div>
                  <div class="field mr-4 mb-0">
                    <input
                      class="input"
                      v-model="halfNotAvailableEndTime"
                      placeholder="14:00"
                      pattern="^\d\d:\d\d$"
                      @input="checkTimesAreValidAndSet4hStep"
                      :class="{ 'is-danger': timesInvalidity }"
                      :disabled="true"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                (notAvailable || unplannedUnavailability) &&
                !markedByPartTimeUser
              "
              class="make-up-time-check"
            >
              <label class="checkbox">
                <input type="checkbox" v-model="makingUpUnavailability" />
                Make up absence 📅
              </label>
              <div v-if="makingUpUnavailability">
                <p class="make-up-absence-info">
                  If you are planning to make up the absence, write down below a
                  comment with a predicted day.
                </p>
              </div>
            </div>

            <div class="label mt-4">Comment</div>
            <textarea
              class="textarea"
              :placeholder="commentPlaceholderText"
              v-model="comment"
            />
          </div>

          <article
            v-if="notAvailable && !markedByPartTimeUser"
            class="message is-warning mt-4"
          >
            <div class="message-header">
              <p>Absences will be reviewed by PMs</p>
            </div>
          </article>
          <div class="apply-unavailability">
            Apply
            <button
              class="button is-danger is-outlined ml-2 mr-2"
              :disabled="haveToFillTimeWhenHalfAbsence"
              @click="
                emit('apply-not-available-to-selection', notAvailableIndicators)
              "
            >
              <span class="icon">
                <i class="fa fa-calendar-times"></i>
              </span>
              <span>{{ finishMarkingUnavailabilityText }}</span>
            </button>
          </div>
        </div>
      </div>

      <div v-if="activeTab === MyWorkTab.Remove">
        <button
          class="button is-danger is-outlined"
          @click="emit('remove-selection')"
        >
          <span>Remove day{{ selectedDays.length > 1 ? 's' : '' }}</span>
          <span class="icon">
            <i class="fa fa-trash"></i>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import SelectDayTemplate from '@/components/select-day-template.vue'
import DayTemplateForm from '@/components/day-template-form.vue'
import { defineComponent, PropType, ref, watch } from 'vue'
import { MyWorkTab } from '@/interfaces'
import { DayDto } from 'service/__generated-api'
import { parse as parseDate } from 'date-fns'
import { notAvailableArgs } from '@/utils'
import { useStore } from 'vuex'

export default defineComponent({
  components: { SelectDayTemplate, DayTemplateForm },
  data() {
    return {
      timesInvalidity: false
    }
  },
  props: {
    activeTab: {
      required: true,
      type: String as PropType<MyWorkTab>
    },
    selectedDay: {
      required: true,
      type: Object as PropType<DayDto>
    },
    selectedDays: {
      required: true,
      type: Array as PropType<string[]>
    }
  },
  computed: {
    isUserPartTimeEmployee() {
      return useStore().state.user.worksPartTime
    },
    notAvailableIndicators(): notAvailableArgs {
      return {
        comment: this.comment,
        halfNotAvailable: this.halfNotAvailable,
        makingUpUnavailability: this.makingUpUnavailability,
        atConference: this.atConference,
        unplannedUnavailability: this.unplannedUnavailability,
        sick: this.sick,
        notAvailable: this.notAvailable,
        halfNotAvailableStartTime: this.halfNotAvailableStartTime,
        halfNotAvailableEndTime: this.halfNotAvailableEndTime,
        markedByPartTimeUser: this.markedByPartTimeUser
      }
    },
    commentPlaceholderText(): string {
      if (this.unplannedUnavailability) {
        return 'e.g. Nieplanowany wyjazd / Odrobienie nieobecności ❌'
      }
      if (this.sick) {
        return 'e.g. Jestem chora/y 🤒🌡️'
      }
      if (this.atConference) {
        return 'e.g. Konferencja 🎓'
      }
      return 'e.g. Urlop / Odrobienie nieobecności ❌'
    },
    haveToFillTimeWhenHalfAbsence(): boolean {
      return (
        this.halfNotAvailable &&
        !this.halfNotAvailableStartTime &&
        !this.halfNotAvailableEndTime
      )
    },
    selectedAbsenceType() {
      if (this.unplannedUnavailability) {
        return 'unplannedUnavailability'
      } else if (this.sick) {
        return 'sick'
      } else if (this.atConference) {
        return 'atConference'
      }
      return 'notAvailable'
    },
    absenceOptions() {
      return [
        { key: 'notAvailable', name: 'Absent' },
        { key: 'unplannedUnavailability', name: 'Unplanned absence' },
        { key: 'sick', name: 'Sick' },
        { key: 'atConference', name: 'Course/Conference' }
      ]
    },
    finishMarkingUnavailabilityText(): string {
      if (this.sick) return 'I am sick'
      if (this.atConference) return 'Going on course or conference'
      if (this.unplannedUnavailability) return 'Unplanned not available'
      return 'Not available'
    }
  },
  methods: {
    checkTimesAreValidAndSet4hStep() {
      const formatRegex = /^\d\d:\d\d$/
      if (!formatRegex.test(this.halfNotAvailableStartTime))
        this.timesInvalidity = true
      else
        this.timesInvalidity = !formatRegex.test(this.halfNotAvailableEndTime)
      if (!this.timesInvalidity) this.set4hTimeStep()
    },
    set4hTimeStep() {
      const fromTime = parseDate(
        this.halfNotAvailableStartTime,
        'HH:mm',
        new Date()
      )
      const limitedToTimeByMax4hStepPerDay = parseDate(
        '20:00',
        'HH:mm',
        new Date()
      )
      if (!fromTime.getTime() || fromTime > limitedToTimeByMax4hStepPerDay) {
        this.timesInvalidity = true
      } else {
        const dateToStr = (date: Date) => {
          return date.toTimeString().split(' ')[0].slice(0, -3)
        }
        const addHours = (date: Date, hours: number) => {
          date.setHours(date.getHours() + hours)
          return date
        }
        const newEndTimeWithAdded4h = addHours(fromTime, 4)
        this.halfNotAvailableEndTime = dateToStr(newEndTimeWithAdded4h)
      }
    },
    setDefaultTime() {
      this.halfNotAvailableStartTime = this.halfNotAvailableStartTime
        ? ''
        : '10:00'
      this.halfNotAvailableEndTime = this.halfNotAvailableEndTime ? '' : '14:00'
    },
    adjustChecksDueToPartTimeJob() {
      if (this.markedByPartTimeUser) {
        this.halfNotAvailable = false
        this.makingUpUnavailability = false
        this.halfNotAvailableStartTime = ''
        this.halfNotAvailableEndTime = ''
      }
    },
    onAbsenceTypeChange(event: string) {
      if (event === 'sick') {
        this.sick = true
        this.unplannedUnavailability = false
        this.atConference = false
        this.notAvailable = false
        this.markedByPartTimeUser = false
      } else if (event == 'unplannedUnavailability') {
        this.sick = false
        this.unplannedUnavailability = true
        this.atConference = false
        this.notAvailable = false
        this.markedByPartTimeUser = false
      } else if (event == 'atConference') {
        this.sick = false
        this.unplannedUnavailability = false
        this.atConference = true
        this.notAvailable = false
        this.markedByPartTimeUser = false
      } else {
        this.sick = false
        this.unplannedUnavailability = false
        this.atConference = false
        this.notAvailable = true
        this.markedByPartTimeUser = false
      }
      // clear half absence attrs
      this.halfNotAvailable = false
      this.makingUpUnavailability = false
      this.halfNotAvailableStartTime = ''
      this.halfNotAvailableEndTime = ''
      this.markedByPartTimeUser = false
    }
  },
  emits: [
    'active-tab-change',
    'remove-selection',
    'apply-to-selection',
    'apply-holiday-to-selection',
    'apply-not-available-to-selection'
  ],
  setup(props, { emit }) {
    const comment = ref(props.selectedDay.comment || '')
    const halfNotAvailable = ref(props.selectedDay.halfNotAvailable || false)
    const markedByPartTimeUser = ref(
      props.selectedDay.markedByPartTimeUser || false
    )
    const makingUpUnavailability = ref(
      props.selectedDay.makingUpUnavailability || false
    )
    const atConference = ref(props.selectedDay.atConference || false)
    const unplannedUnavailability = ref(
      props.selectedDay.unplannedUnavailability || false
    )
    const sick = ref(props.selectedDay.sick || false)
    const notAvailable = ref(props.selectedDay.notAvailable || false)
    notAvailable.value =
      !unplannedUnavailability.value && !atConference.value && !sick.value
    const halfNotAvailableStartTime = ref(
      props.selectedDay.halfNotAvailableStartTime || ''
    )
    const halfNotAvailableEndTime = ref(
      props.selectedDay.halfNotAvailableEndTime || ''
    )

    watch(props.selectedDay, () => {
      comment.value = props.selectedDay.comment || ''
      halfNotAvailable.value = props.selectedDay.halfNotAvailable || false
      markedByPartTimeUser.value =
        props.selectedDay.markedByPartTimeUser || false
      makingUpUnavailability.value =
        props.selectedDay.makingUpUnavailability || false
      sick.value = props.selectedDay.sick || false
      atConference.value = props.selectedDay.atConference || false
      unplannedUnavailability.value =
        props.selectedDay.unplannedUnavailability || false
      halfNotAvailableStartTime.value =
        props.selectedDay.halfNotAvailableStartTime || ''
      halfNotAvailableEndTime.value =
        props.selectedDay.halfNotAvailableEndTime || ''
      notAvailable.value =
        !unplannedUnavailability.value && !atConference.value && !sick.value
    })

    return {
      MyWorkTab,
      emit,
      comment,
      halfNotAvailable,
      makingUpUnavailability,
      atConference,
      unplannedUnavailability,
      sick,
      notAvailable,
      halfNotAvailableStartTime,
      halfNotAvailableEndTime,
      markedByPartTimeUser
    }
  }
})
</script>

<style lang="scss" scoped>
.methods-wrap {
  .button {
    position: relative;
    top: -8px;
  }
}

.message,
.not-available-box,
.comment-wrap {
  max-width: 380px;
}
.labels {
  max-width: 347px;
  display: flex;
}
.label {
  width: 50%;
}
.apply-unavailability {
  margin-top: 23px;
}
.work-part-time-check,
.make-up-time-check,
.half-day-check {
  margin-bottom: 4px;
  margin-top: 4px;
  padding: 2px 2px 2px 0;
}
.half-day-time {
  margin-bottom: 15px;
  margin-top: 4px;
}
.make-up-absence-info,
.half-day-absence-info,
.half-day-absence-info strong {
  font-size: 13px;
  color: #4000ff;
  margin-bottom: 5px;
  margin-top: 4px;
}
</style>
