<template>
  <nav
    class="navbar has-text-white-ter"
    :class="{ 'navbar--active': $store.state.user.isLogged }"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <div class="navbar-item logo">Sparing Timer</div>

      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-start">
        <router-link to="/daily" class="navbar-item">
          <div class="icon-text">
            <span class="icon">
              <i class="fas fa-users"></i>
            </span>
            <span>Daily</span>
          </div>
        </router-link>

        <router-link to="/monthly" class="navbar-item">
          <div class="icon-text">
            <span class="icon">
              <i class="fa fa-calendar-alt"></i>
            </span>
            <span>Monthly</span>
          </div>
        </router-link>

        <router-link to="/my-work" class="navbar-item">
          <div class="icon-text">
            <span class="icon">
              <i class="fas fa-user-clock"></i>
            </span>
            <span>My work</span>
          </div>
        </router-link>

        <router-link to="/my-templates" class="navbar-item">
          <div class="icon-text">
            <span class="icon">
              <i class="fas fa-stream"></i>
            </span>
            <span>My templates</span>
          </div>
        </router-link>

        <router-link
          v-if="user.role === 'admin'"
          to="/absences-report"
          class="navbar-item"
        >
          <div class="icon-text">
            <span class="icon">
              <i class="fas fa-chart-bar"></i>
            </span>
            <span>Absences report</span>
          </div>
        </router-link>
      </div>

      <div class="navbar-end">
        <div class="navbar-item has-dropdown is-hoverable user-menu">
          <div class="navbar-item mobile-user">
            {{ user.firstName }} {{ user.lastName }}
            <figure class="image is-24x24 ml-3">
              <img class="user-picture is-rounded" :src="user.picture" alt="" />
            </figure>
          </div>

          <div class="navbar-dropdown">
            <router-link to="/mattermost" class="navbar-item">
              <div class="icon-text">
                <span class="icon">
                  <img
                    class="image is-16x16"
                    src="https://mattermost.sparing.cloud/static/images/favicon/favicon-32x32.png"
                    alt=""
                  />
                </span>
                <span>Mattermost</span>
              </div>
            </router-link>

            <router-link
              v-if="user.role === 'admin'"
              to="/server-settings"
              class="navbar-item"
            >
              <div class="icon-text">
                <span class="icon">
                  <i class="fas fa-cog"></i>
                </span>
                <span>Server settings</span>
              </div>
            </router-link>

            <router-link
              v-if="user.role === 'admin'"
              to="/users-authorization"
              class="navbar-item"
            >
              <div class="icon-text">
                <span class="icon">
                  <i class="fas fa-users-cog"></i>
                </span>
                <span>Users settings</span>
              </div>
            </router-link>

            <router-link
              v-if="user.role === 'admin'"
              to="/teams"
              class="navbar-item"
            >
              <div class="icon-text">
                <span class="icon">
                  <i class="fas fa-users"></i>
                </span>
                <span>Teams</span>
              </div>
            </router-link>

            <a
              v-if="user.role === 'admin'"
              class="navbar-item"
              @click.prevent="downloadHourReport"
            >
              <div class="icon-text">
                <span class="icon">
                  <i class="fa fa-download"></i>
                </span>
                <span>Month report</span>
              </div>
            </a>

            <a @click.prevent="logout" class="navbar-item">
              <div class="icon-text">
                <span class="icon has-text-danger">
                  <i class="fas fa-sign-out-alt"></i>
                </span>
                <span>Logout</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import djangoApi from '@/django-api'
import { downloadXlsxResponse } from '@/utils'

export default defineComponent({
  setup() {
    const store = useStore()
    const router = useRouter()

    function logout() {
      store.commit('user/logout')
      router.replace('/login')
    }

    async function downloadHourReport() {
      const response = await djangoApi.admin.prismaHoursReportRetrieve({
        format: 'blob'
      })
      downloadXlsxResponse(response)
    }

    const user = store.state.user

    return { logout, downloadHourReport, user }
  }
})
</script>

<style lang="scss" scoped>
.navbar {
  transform: translateY(-100%);
  transition: transform 0.2s;

  &--active {
    transform: translateY(0);
  }
}

.logo {
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 20px;
  padding: 0 20px;

  &:focus,
  &:hover {
    color: #4a4a4a;
  }
}

.icon-text {
  flex-wrap: nowrap;
}

.mobile-user {
  @media (max-width: 1024px) {
    margin-top: 30px;

    figure {
      display: none;
    }
  }
}
</style>
