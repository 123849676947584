<template>
  <div>
    <div class="container">
      <div class="section">
        <div class="columns">
          <div class="column">
            <h1 class="title">Monthly availability</h1>
          </div>
          <div class="column is-narrow">
            <teams-dropdown />
          </div>
        </div>
        <hr />
        <team-monthly-calendar />
      </div>
    </div>
    <div class="monthly-wrap"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TeamMonthlyCalendar from '@/components/calendars/team-monthly.vue'
import TeamsDropdown from '@/components/teams-dropdown.vue'

export default defineComponent({
  components: { TeamsDropdown, TeamMonthlyCalendar }
})
</script>

<style lang="scss" scoped>
.monthly-wrap {
  margin: 0 30px;
}
.section {
  padding-bottom: 0;
}
</style>
