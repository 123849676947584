
import { DayTemplateDto } from 'service/__generated-api'
import { defineComponent, ref, unref, watch } from 'vue'
import { useStore } from 'vuex'
import DayTemplatePreview from '@/components/day-template-preview.vue'

export default defineComponent({
  components: { DayTemplatePreview },
  props: ['submitText'],
  emits: ['submit'],
  setup(props, { emit }) {
    const store = useStore()
    const dayTemplates = store.state.user.dayTemplates as DayTemplateDto[]
    const selectedDayTemplate = ref(dayTemplates[0])
    const selectedId = ref(dayTemplates[0].id)

    watch(selectedId, (id: number) => {
      selectedDayTemplate.value = dayTemplates.find(
        (template: DayTemplateDto) => template.id === id
      ) as DayTemplateDto
    })

    function submit() {
      emit('submit', unref(selectedDayTemplate.value))
    }

    return { selectedId, submit, selectedDayTemplate }
  }
})
