<template>
  <div class="period-picker mb-3">
    <div class="field is-horizontal">
      <div class="field mr-4 mb-0">
        <input
          class="input"
          :class="{ 'is-danger': hasFromError }"
          placeholder="00:00"
          ref="fromInput"
          pattern="^\d\d:\d\d$"
          :value="modelValue.from"
          @input="
            $event =>
              emit('update:modelValue', {
                ...modelValue,
                from: $event.target.value
              })
          "
        />
      </div>
      <div class="field mr-4 mb-0">
        <input
          class="input"
          placeholder="00:00"
          ref="toInput"
          pattern="^\d\d:\d\d$"
          :class="{ 'is-danger': hasToError }"
          :value="modelValue.to"
          @input="
            $event =>
              emit('update:modelValue', {
                ...modelValue,
                to: $event.target.value
              })
          "
        />
      </div>
      <div class="field">
        <button
          type="button"
          :disabled="disableDelete"
          class="button"
          @click="emit('remove')"
        >
          <span class="icon is-small">
            <i class="fas fa-times"></i>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { isAfter, parse as parseDate } from 'date-fns'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  emits: ['remove', 'update:modelValue'],
  props: ['modelValue', 'disableDelete'],
  setup(props, { emit }) {
    const fromInput = ref<HTMLInputElement>()
    const toInput = ref<HTMLInputElement>()
    const hasFromError = ref(false)
    const hasToError = ref(false)
    const formatRegex = /^\d\d:\d\d$/

    watch(
      () => [props.modelValue.from, props.modelValue.to],
      () => {
        hasFromError.value = false
        hasToError.value = false

        const { from, to } = props.modelValue

        if (!formatRegex.test(from)) {
          hasFromError.value = true
        }

        if (!formatRegex.test(to)) {
          hasToError.value = true
        }

        const fromDate = parseDate(from, 'HH:mm', new Date())
        const toDate = parseDate(to, 'HH:mm', new Date())

        const isPeriodError = !isAfter(toDate, fromDate)

        toInput.value?.setCustomValidity(
          isPeriodError ? '"To" must be after "From"' : ''
        )

        if (isPeriodError) {
          hasToError.value = true
          hasFromError.value = true
        }
      }
    )

    return { emit, hasFromError, hasToError, fromInput, toInput }
  }
})
</script>

<style lang="scss" scoped>
.is-horizontal {
  display: flex !important;
  justify-content: space-between;
  max-width: 300px;
}

.input {
  width: 134px;
}
</style>
