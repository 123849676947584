<template>
  <div class="container mt-6">
    <div class="section">
      <h1 class="title">My templates</h1>
      <p>
        Create <strong>Day Templates</strong> to easily manage
        <strong>Work Periods</strong> in
        <router-link to="my-work">your timeline</router-link>!
      </p>

      <hr />

      <loader :is-loading="isLoading">
        <div class="is-flex is-flex-wrap-wrap">
          <day-template-preview
            v-for="dayTemplate in dayTemplates"
            :key="dayTemplate.id"
            :dayTemplate="dayTemplate"
            @remove="onTemplateRemove"
            editable
            class="item"
          />

          <router-link class="box create-button item" to="/my-templates/create">
            <a class="icon-text card-footer-item">
              <span class="icon is-large">
                <i class="fas fa-lg fa-calendar-day"></i>
              </span>
              <span class="subtitle is-size-4">Add Template</span>
            </a>
          </router-link>
        </div>
      </loader>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue'
import { useApi } from '@/api'
import { DayTemplateDto } from 'service/__generated-api'
import DayTemplatePreview from '@/components/day-template-preview.vue'
import { useStore } from 'vuex'

export default defineComponent({
  components: { DayTemplatePreview },
  setup() {
    const store = useStore()
    const dayTemplatesRes = useApi<DayTemplateDto[]>(api =>
      api.dayTemplates.myTempaltes()
    )

    watch(dayTemplatesRes.data, dayTemplates => {
      store.commit('user/setDayTemplates', dayTemplates)
    })

    function onTemplateRemove(templateId: number) {
      const index = dayTemplatesRes.data.value?.findIndex(dayTemplate => {
        return dayTemplate.id === templateId
      })

      if (index !== undefined) {
        dayTemplatesRes.data.value?.splice(index, 1)
      }
    }

    return {
      isLoading: dayTemplatesRes.isLoading,
      dayTemplates: dayTemplatesRes.data,
      onTemplateRemove
    }
  }
})
</script>

<style lang="scss" scoped>
.is-flex {
  margin: 0 -10px;
}

.item {
  width: calc(100% / 3);
  margin: 10px;
}

.create-button {
  width: 280px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-color 0.15s;
  border: 1px solid #9be5b5;

  span {
    color: #3db366;
  }

  &:hover,
  &:focus {
    border: 1px solid #3fc46d;
    box-shadow: 0 0.5em 1em -0.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05 !important;
  }
}
</style>
