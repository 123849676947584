
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import djangoApi from '@/django-api'
import { downloadXlsxResponse } from '@/utils'

export default defineComponent({
  setup() {
    const store = useStore()
    const router = useRouter()

    function logout() {
      store.commit('user/logout')
      router.replace('/login')
    }

    async function downloadHourReport() {
      const response = await djangoApi.admin.prismaHoursReportRetrieve({
        format: 'blob'
      })
      downloadXlsxResponse(response)
    }

    const user = store.state.user

    return { logout, downloadHourReport, user }
  }
})
