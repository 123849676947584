import { watch } from 'vue'
import { ref } from 'vue'

export const showPeriodsInMonthlyView = ref(
  window.localStorage.getItem('showPeriodsInMonthlyView') === 'true'
)

watch(showPeriodsInMonthlyView, () => {
  window.localStorage.setItem(
    'showPeriodsInMonthlyView',
    `${showPeriodsInMonthlyView.value}`
  )
})
