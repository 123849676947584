import { parseDate } from '@/utils'
import { addDays, format, isWeekend } from 'date-fns'

export function isBusinessDay(formattedDate: string): boolean {
  const date = parseDate(formattedDate)
  let isBusinessDay = true

  if (isWeekend(date)) {
    isBusinessDay = false
  }

  // Nowy rok
  if (format(date, 'dd.MM') === '01.01') {
    isBusinessDay = false
  }

  // Trzech Króli
  if (format(date, 'dd.MM') === '06.01') {
    isBusinessDay = false
  }

  // Wielkanoc
  const paschaDays = [
    '28.03.2100',
    '12.04.2099',
    '20.04.2098',
    '31.03.2097',
    '15.04.2096',
    '24.04.2095',
    '04.04.2094',
    '12.04.2093',
    '30.03.2092',
    '08.04.2091',
    '16.04.2090',
    '03.04.2089',
    '11.04.2088',
    '20.04.2087',
    '31.03.2086',
    '15.04.2085',
    '26.03.2084',
    '04.04.2083',
    '19.04.2082',
    '30.03.2081',
    '07.04.2080',
    '23.04.2079',
    '03.04.2078',
    '11.04.2077',
    '19.04.2076',
    '07.04.2075',
    '15.04.2074',
    '26.03.2073',
    '10.04.2072',
    '19.04.2071',
    '30.03.2070',
    '14.04.2069',
    '22.04.2068',
    '03.04.2067',
    '11.04.2066',
    '29.03.2065',
    '06.04.2064',
    '15.04.2063',
    '26.03.2062',
    '10.04.2061',
    '18.04.2060',
    '30.03.2059',
    '14.04.2058',
    '22.04.2057',
    '02.04.2056',
    '18.04.2055',
    '29.03.2054',
    '06.04.2053',
    '21.04.2052',
    '02.04.2051',
    '10.04.2050',
    '18.04.2049',
    '05.04.2048',
    '14.04.2047',
    '25.03.2046',
    '09.04.2045',
    '17.04.2044',
    '29.03.2043',
    '06.04.2042',
    '21.04.2041',
    '01.04.2040',
    '10.04.2039',
    '25.04.2038',
    '05.04.2037',
    '13.04.2036',
    '25.03.2035',
    '09.04.2034',
    '17.04.2033',
    '28.03.2032',
    '13.04.2031',
    '21.04.2030',
    '01.04.2029',
    '16.04.2028',
    '28.03.2027',
    '05.04.2026',
    '20.04.2025',
    '31.03.2024',
    '09.04.2023',
    '17.04.2022',
    '04.04.2021',
    '12.04.2020',
    '21.04.2019',
    '01.04.2018',
    '16.04.2017',
    '27.03.2016',
    '05.04.2015',
    '20.04.2014',
    '31.03.2013',
    '08.04.2012',
    '24.04.2011',
    '04.04.2010',
    '12.04.2009',
    '23.03.2008',
    '08.04.2007',
    '16.04.2006',
    '27.03.2005',
    '11.04.2004',
    '20.04.2003',
    '31.03.2002',
    '15.04.2001'
  ]
  if (paschaDays.includes(format(date, 'dd.MM.yyyy'))) {
    isBusinessDay = false
  }

  const easterMondayDays = paschaDays.map(day =>
    format(addDays(parseDate(day, 'dd.MM.yyyy'), 1), 'dd.MM.yyyy')
  )
  // Poniedziałek Wielkanocny
  if (easterMondayDays.includes(format(date, 'dd.MM.yyyy'))) {
    isBusinessDay = false
  }

  // Święto pracy
  if (format(date, 'dd.MM') === '01.05') {
    isBusinessDay = false
  }

  // Święto Konstytuacji 3 Maja
  if (format(date, 'dd.MM') === '03.05') {
    isBusinessDay = false
  }

  // Zielone Świątki
  const pentecostsDays = [
    '16.05.2100',
    '31.05.2099',
    '08.06.2098',
    '19.05.2097',
    '03.06.2096',
    '12.06.2095',
    '23.05.2094',
    '31.05.2093',
    '18.05.2092',
    '27.05.2091',
    '04.06.2090',
    '22.05.2089',
    '30.05.2088',
    '08.06.2087',
    '19.05.2086',
    '03.06.2085',
    '14.05.2084',
    '23.05.2083',
    '07.06.2082',
    '18.05.2081',
    '26.05.2080',
    '11.06.2079',
    '22.05.2078',
    '30.05.2077',
    '07.06.2076',
    '26.05.2075',
    '03.06.2074',
    '14.05.2073',
    '29.05.2072',
    '07.06.2071',
    '18.05.2070',
    '02.06.2069',
    '10.06.2068',
    '22.05.2067',
    '30.05.2066',
    '17.05.2065',
    '25.05.2064',
    '03.06.2063',
    '14.05.2062',
    '29.05.2061',
    '06.06.2060',
    '18.05.2059',
    '02.06.2058',
    '10.06.2057',
    '21.05.2056',
    '06.06.2055',
    '17.05.2054',
    '25.05.2053',
    '09.06.2052',
    '21.05.2051',
    '29.05.2050',
    '06.06.2049',
    '24.05.2048',
    '02.06.2047',
    '13.05.2046',
    '28.05.2045',
    '05.06.2044',
    '17.05.2043',
    '25.05.2042',
    '09.06.2041',
    '20.05.2040',
    '29.05.2039',
    '13.06.2038',
    '24.05.2037',
    '01.06.2036',
    '13.05.2035',
    '28.05.2034',
    '05.06.2033',
    '16.05.2032',
    '01.06.2031',
    '09.06.2030',
    '20.05.2029',
    '04.06.2028',
    '16.05.2027',
    '24.05.2026',
    '08.06.2025',
    '19.05.2024',
    '28.05.2023',
    '05.06.2022',
    '23.05.2021',
    '31.05.2020',
    '09.06.2019',
    '20.05.2018',
    '04.06.2017',
    '15.05.2016',
    '24.05.2015',
    '08.06.2014',
    '19.05.2013',
    '27.05.2012',
    '12.06.2011',
    '23.05.2010',
    '31.05.2009',
    '11.05.2008',
    '27.05.2007',
    '04.06.2006',
    '15.05.2005',
    '30.05.2004',
    '08.06.2003',
    '19.05.2002',
    '03.06.2001'
  ]
  if (pentecostsDays.includes(format(date, 'dd.MM.yyyy'))) {
    isBusinessDay = false
  }

  // Dzień Bożego Ciała
  const feastOfCorpusChristiDays = [
    '27.05.2100',
    '11.06.2099',
    '19.06.2098',
    '30.05.2097',
    '14.06.2096',
    '23.06.2095',
    '03.06.2094',
    '11.06.2093',
    '29.05.2092',
    '07.06.2091',
    '15.06.2090',
    '02.06.2089',
    '10.06.2088',
    '19.06.2087',
    '30.05.2086',
    '14.06.2085',
    '25.05.2084',
    '03.06.2083',
    '18.06.2082',
    '29.05.2081',
    '06.06.2080',
    '22.06.2079',
    '02.06.2078',
    '10.06.2077',
    '18.06.2076',
    '06.06.2075',
    '14.06.2074',
    '25.05.2073',
    '09.06.2072',
    '18.06.2071',
    '29.05.2070',
    '13.06.2069',
    '21.06.2068',
    '02.06.2067',
    '10.06.2066',
    '28.05.2065',
    '05.06.2064',
    '14.06.2063',
    '25.05.2062',
    '09.06.2061',
    '17.06.2060',
    '29.05.2059',
    '13.06.2058',
    '21.06.2057',
    '01.06.2056',
    '17.06.2055',
    '28.05.2054',
    '05.06.2053',
    '20.06.2052',
    '01.06.2051',
    '09.06.2050',
    '17.06.2049',
    '04.06.2048',
    '13.06.2047',
    '24.05.2046',
    '08.06.2045',
    '16.06.2044',
    '28.05.2043',
    '05.06.2042',
    '20.06.2041',
    '31.05.2040',
    '09.06.2039',
    '24.06.2038',
    '04.06.2037',
    '12.06.2036',
    '24.05.2035',
    '08.06.2034',
    '16.06.2033',
    '27.05.2032',
    '12.06.2031',
    '20.06.2030',
    '31.05.2029',
    '15.06.2028',
    '27.05.2027',
    '04.06.2026',
    '19.06.2025',
    '30.05.2024',
    '08.06.2023',
    '16.06.2022',
    '03.06.2021',
    '11.06.2020',
    '20.06.2019',
    '31.05.2018',
    '15.06.2017',
    '26.05.2016',
    '04.06.2015',
    '19.06.2014',
    '30.05.2013',
    '07.06.2012',
    '23.06.2011',
    '03.06.2010',
    '11.06.2009',
    '22.05.2008',
    '07.06.2007',
    '15.06.2006',
    '26.05.2005',
    '10.06.2004',
    '19.06.2003',
    '30.05.2002',
    '14.06.2001'
  ]
  if (feastOfCorpusChristiDays.includes(format(date, 'dd.MM.yyyy'))) {
    isBusinessDay = false
  }

  // Wniebowzięcie NMP
  if (format(date, 'dd.MM') === '15.08') {
    isBusinessDay = false
  }

  // Wszystkich Świętych
  if (format(date, 'dd.MM') === '01.11') {
    isBusinessDay = false
  }

  // Narodowe Święto Niepodległości
  if (format(date, 'dd.MM') === '11.11') {
    isBusinessDay = false
  }

  // I Dzień Bożego Narodzenia
  if (format(date, 'dd.MM') === '25.12') {
    isBusinessDay = false
  }

  // II Dzień Bożego Narodzenia
  if (format(date, 'dd.MM') === '26.12') {
    isBusinessDay = false
  }

  return isBusinessDay
}
