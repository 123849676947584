
import { defineComponent, ref } from 'vue'
import DayPeriodsPicker from '@/components/day-template-form.vue'
import { CreateDayTemplateDto } from 'service/__generated-api'
import api from '@/api'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: { DayPeriodsPicker },
  setup() {
    const router = useRouter()
    const isLoading = ref(false)
    async function onCreateTemplate(dayTemaplate: CreateDayTemplateDto) {
      isLoading.value = true

      try {
        await api.dayTemplates.add(dayTemaplate)

        router.push('/my-templates')
      } catch (err) {
        console.error(err)
      }

      isLoading.value = false
    }

    return { onCreateTemplate, isLoading }
  }
})
