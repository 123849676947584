<template>
  <tr class="row">
    <td height="35"></td>
    <user-cell
      v-for="day in user.days"
      :key="day.formattedDate"
      :user="user"
      :day="day"
    />
  </tr>
</template>

<script lang="ts">
import { TimelineUserDto } from 'service/__generated-api'
import { defineComponent, PropType } from 'vue'
import UserCell from '@/components/calendars/team-monthly-user-cell.vue'

export default defineComponent({
  components: { UserCell },
  props: {
    user: {
      required: true,
      type: Object as PropType<TimelineUserDto>
    }
  }
})
</script>
