<template>
  <form class="wrap box" @submit.prevent="onSubmit">
    <div class="field mb-5" v-if="withTemplateName">
      <label class="label">Template name*</label>
      <div class="control">
        <input
          class="input"
          v-model="templateName"
          type="text"
          placeholder="Default day"
          required
          minlength="3"
          maxlength="255"
        />
      </div>
    </div>

    <div class="label">Working</div>
    <div class="mb-4">
      <label class="checkbox">
        <input type="checkbox" v-model="remote" />
        Remote
      </label>
    </div>

    <periods-picker v-model="periods" />

    <div v-if="!withTemplateName">
      <div class="label mt-4">Comment</div>
      <textarea
        class="textarea"
        placeholder="e.g. Idę z psem na spacer o 16"
        v-model="comment"
      />
    </div>

    <div class="is-flex is-justify-content-flex-end mt-4">
      <router-link
        class="button mr-4 is-outlined is-danger"
        to="/my-templates"
        v-if="cancelText"
      >
        {{ cancelText }}
      </router-link>
      <button
        class="button is-success is-outlined"
        :class="{ 'is-loading': isSubmitLoading }"
        type="submit"
      >
        {{ submitText }}
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from 'vue'
import { DayTemplateDto } from 'service/__generated-api'
import PeriodsPicker from '@/components/periods-picker.vue'

export default defineComponent({
  components: { PeriodsPicker },
  props: {
    dayTemplate: {
      required: true,
      type: Object as PropType<DayTemplateDto>
    },
    submitText: String,
    cancelText: String,
    isSubmitLoading: Boolean,
    withTemplateName: {
      type: Boolean,
      default: false
    }
  },
  emits: ['submit'],
  setup(props, { emit }) {
    const templateName = ref(props.dayTemplate?.name ?? '')
    const periods = ref(props.dayTemplate?.periods || '08:00-16:00')
    const remote = ref(props.dayTemplate?.remote || false)
    const comment = ref(props.dayTemplate?.comment || '')

    function onSubmit() {
      emit('submit', {
        name: templateName.value,
        periods: periods.value,
        remote: remote.value,
        comment: comment.value
      })
    }

    watch(props.dayTemplate, () => {
      remote.value = props.dayTemplate.remote
      periods.value = props.dayTemplate.periods
      comment.value = props.dayTemplate.comment || ''
    })

    return { templateName, onSubmit, periods, remote, comment }
  }
})
</script>

<style lang="scss" scoped>
.wrap {
  max-width: 380px;
}
</style>
