import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import Login from '@/views/login.vue'
import MyWork from '@/views/my-work.vue'
import MyTemplates from '@/views/my-templates.vue'
import MyTemplatesCreate from '@/views/my-templates-create.vue'
import MyTemplatesEdit from '@/views/my-templates-edit.vue'
import Monthly from '@/views/monthly.vue'
import Mattermost from '@/views/mattermost.vue'
import Daily from '@/views/daily.vue'
import { formatDate } from '@/utils'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Daily,
    meta: { title: 'Daily' },
    beforeEnter(to, from, next) {
      next('/daily/' + formatDate(new Date()))
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Login' },
    beforeEnter: (to, from, next) => {
      if (to.query.token) {
        next('/')
        return
      }

      next()
    }
  },
  {
    path: '/my-work/:formattedDate?',
    name: 'MyWork',
    component: MyWork,
    meta: { title: 'My work' }
  },
  {
    path: '/my-templates/create',
    name: 'MyTemplatesCreate',
    component: MyTemplatesCreate,
    meta: { title: 'Create temaplate' }
  },
  {
    path: '/my-templates/edit/:id',
    name: 'MyTemaplteEdit',
    component: MyTemplatesEdit,
    meta: { title: 'Edit template' }
  },
  {
    path: '/my-templates',
    name: 'MyTemplates',
    component: MyTemplates,
    meta: { title: 'My templates' }
  },
  {
    path: '/daily/:formattedDate',
    name: 'Daily',
    component: Daily,
    meta: { title: 'Daily' }
  },
  {
    path: '/daily',
    name: 'DailyRaw',
    component: Daily,
    meta: { title: 'Daily' },
    beforeEnter(to, from, next) {
      next('/daily/' + formatDate(new Date()))
    }
  },
  {
    path: '/monthly/:formattedDate',
    name: 'Monthly',
    component: Monthly,
    meta: { title: 'Monthly' }
  },
  {
    path: '/monthly/',
    name: 'MonthlyRaw',
    component: Monthly,
    beforeEnter(to, from, next) {
      next('/monthly/' + formatDate(new Date()))
    }
  },
  {
    path: '/mattermost',
    name: 'Mattermost',
    component: Mattermost,
    meta: { title: 'Mattermost integration' }
  },
  {
    path: '/server-settings',
    name: 'ServerSettings',
    component: () => import('../views/server-settings.vue'),
    meta: { title: 'Server settings' }
  },
  {
    path: '/users-authorization',
    name: 'UsersAuthorization',
    component: () => import('../views/users-authorization.vue'),
    meta: { title: 'Users Authorization' }
  },
  {
    path: '/teams',
    name: 'Teams',
    component: () => import('../views/teams.vue'),
    meta: { title: 'Teams' }
  },
  {
    path: '/teams/:id',
    name: 'TeamEdit',
    component: () => import('../views/team.vue'),
    meta: { title: 'Edit team' }
  },
  {
    path: '/teams/create',
    name: 'TeamCreate',
    component: () => import('../views/team.vue'),
    meta: { title: 'Create team' }
  },
  {
    path: '/absences-report/:formattedDate',
    name: 'AbsencesReport',
    component: () => import('../views/absences-report.vue'),
    meta: { title: 'Absence report' }
  },
  {
    path: '/absences-report/',
    name: 'AbsencesReportRaw',
    component: () => import('../views/absences-report.vue'),
    beforeEnter(to, from, next) {
      next('/absences-report/' + formatDate(new Date()))
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isLoginPage = to.name === 'Login'
  const isUserLogin = store.state.user.isLogged

  document.title = to.meta.title
    ? `${to.meta.title} - Sparing Timer`
    : 'Sparing Timer'

  if (isUserLogin && isLoginPage) {
    next('/')
    return
  }

  if (!isUserLogin && !isLoginPage) {
    next('/login')
    return
  }

  next()
})

export default router
