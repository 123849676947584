<template>
  <div class="container mt-6">
    <div class="section">
      <h1 class="title">Create new Day Template</h1>
      <p>
        Create <strong>Day Templates</strong> to easily manage
        <strong>Work Periods</strong> in your timeline!
      </p>

      <hr />

      <day-periods-picker
        submit-text="Create Template"
        @submit="onCreateTemplate"
        :is-submit-loading="isLoading"
        with-template-name
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import DayPeriodsPicker from '@/components/day-template-form.vue'
import { CreateDayTemplateDto } from 'service/__generated-api'
import api from '@/api'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: { DayPeriodsPicker },
  setup() {
    const router = useRouter()
    const isLoading = ref(false)
    async function onCreateTemplate(dayTemaplate: CreateDayTemplateDto) {
      isLoading.value = true

      try {
        await api.dayTemplates.add(dayTemaplate)

        router.push('/my-templates')
      } catch (err) {
        console.error(err)
      }

      isLoading.value = false
    }

    return { onCreateTemplate, isLoading }
  }
})
</script>

<style lang="scss" scoped></style>
