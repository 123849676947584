import { EventContentArg, MountArg } from '@fullcalendar/vue'
import { DayDto } from 'service/__generated-api'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { dayNotFilled } from '@/utils'

function createIcon(emoji: string, title: string) {
  return `<img src="https://mattermost.sparing.cloud/static/emoji/${emoji}" alt="${title}" title="${title}" width="18" class="mr-1" style="display:inline-block">`
}

/** IF YOU TOUCH THIS FUNCTION YOU WILL BE FIRED! */
function eventDidMount(info: MountArg<EventContentArg>): void {
  const day = info.event.extendedProps.day as DayDto
  const wrap = info.el.parentElement?.parentElement?.parentElement
  if (!wrap) return

  wrap.querySelector('.custom-event-description')?.remove()

  const customEventEl = document.createElement('div')
  customEventEl.className = 'custom-event-description'

  if (day.sick) {
    customEventEl.innerHTML += createIcon('1f912.png', 'Sick')
  }

  if (day.unplannedUnavailability) {
    customEventEl.innerHTML += createIcon('274c.png', 'Unplanned absence')
    customEventEl.innerHTML += createIcon('26a1.png', 'Unplanned absence')
  }

  if (day.atConference) {
    customEventEl.innerHTML += createIcon('1f393.png', 'On course/conference')
  }

  if (day.halfNotAvailable) {
    const halfDayAbsenceMsg = `\n${day.halfNotAvailableStartTime} - ${day.halfNotAvailableEndTime} + half-day absence`
    customEventEl.innerHTML += createIcon('1f317.png', halfDayAbsenceMsg)
  }

  if (day.remote) {
    customEventEl.innerHTML += createIcon('1f3e0.png', 'Remote')
  }

  if (day.notAvailable && day.notAvailableAccepted) {
    customEventEl.innerHTML += createIcon('274c.png', 'Not available')
  }

  if (day.markedByPartTimeUser) {
    customEventEl.innerHTML += createIcon('274c.png', 'Part-time job absence')
  } else if (
    day.notAvailable &&
    !day.notAvailableAccepted &&
    !day.markedByPartTimeUser
  ) {
    customEventEl.innerHTML += createIcon('2753.png', 'Absence in review')
  }

  if (day && dayNotFilled(day)) {
    customEventEl.innerHTML += createIcon('1f335.png', 'Kaktus')
  }

  if (day.comment) {
    customEventEl.innerHTML += createIcon('1f4ac.png', `${day.comment}`)
  }

  if (!day.businessDay) {
    wrap.style.backgroundColor = 'rgba(215, 215, 215, 0.4)'
  }

  customEventEl.innerHTML += `<div>${day.periods.split(',').join('<br>')}</div>`

  wrap.appendChild(customEventEl)
}

/** THIS ONE TOO */
function eventWillUnmount(info: MountArg<EventContentArg>): void {
  const wrap = info.el.parentElement?.parentElement?.parentElement
  wrap?.querySelector('.custom-event-description')?.remove()
}

export default {
  plugins: [dayGridPlugin, interactionPlugin],
  initialView: 'dayGridMonth',
  firstDay: 1,
  navLinks: false,
  selectable: true,
  unselectAuto: false,
  height: 500,
  eventBackgroundColor: 'transparent',
  eventBorderColor: 'transparent',
  eventDidMount,
  eventWillUnmount
}
