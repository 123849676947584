<template>
  <Component :is="tag">
    <slot />
  </Component>
</template>

<script>
import smoothReflow from 'vue-smooth-reflow'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default {
  mixins: [smoothReflow],
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    options: {
      type: Object,
      default: () => ({})
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    enabled(enabled) {
      if (enabled) this.$smoothReflow(this.options)
    }
  },
  mounted() {
    if (this.enabled) this.$smoothReflow(this.options)
  }
}
</script>
