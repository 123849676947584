export interface Period {
  from: string
  to: string
}

export enum MyWorkTab {
  Custom = 'Custom',
  Template = 'Template',
  NotAvailable = 'NotAvailable',
  Remove = 'Remove'
}
