
import { TimelineUserDto } from 'service/__generated-api'
import { defineComponent, PropType } from 'vue'
import UserCell from '@/components/calendars/team-monthly-user-cell.vue'

export default defineComponent({
  components: { UserCell },
  props: {
    user: {
      required: true,
      type: Object as PropType<TimelineUserDto>
    }
  }
})
