
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'login',
  layout: 'login',
  setup() {
    const googleAuthUrl = `${process.env.VUE_APP_API_URL}/google`

    return { googleAuthUrl }
  }
})
