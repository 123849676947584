<template>
  <div class="card" :class="{ 'card--editable': editable }">
    <div class="card-content">
      <div class="media">
        <div class="media-left">
          <span class="icon is-medium">
            <i class="fas fa-lg fa-calendar-day"></i>
          </span>
        </div>
        <div class="media-content">
          <div class="mb-2">
            <p class="title is-size-5 mb-0">{{ dayTemplate.name }}</p>
            <label class="checkbox" disabled v-if="dayTemplate.remote">
              <input type="checkbox" value="check" disabled checked />
              Remote
            </label>
          </div>
          <div
            v-for="period in dayTemplate.periods.split(',')"
            :key="period"
            v-text="period"
          />
        </div>
      </div>
    </div>

    <footer v-if="editable" class="card-footer">
      <router-link :to="editLink" class="card-footer-item has-text-info">
        <div class="icon-text">
          <span class="icon">
            <i class="fas fa-edit"></i>
          </span>
          <span>Edit</span>
        </div>
      </router-link>
      <a @click.prevent="removeTemplate" class="card-footer-item">
        <div v-if="isDeleting" class="has-text-danger">
          <span class="icon">
            <i class="fas fa-spinner fa-pulse"></i>
          </span>
        </div>
        <div v-else class="icon-text has-text-grey-dark">
          <span class="icon">
            <i class="fas fa-trash"></i>
          </span>
          <span>Delete</span>
        </div>
      </a>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import { DayTemplateDto } from 'service/__generated-api'
import api from '@/api'

export default defineComponent({
  props: {
    dayTemplate: {
      type: Object as PropType<DayTemplateDto>,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  emits: ['remove'],
  setup(props, { emit }) {
    const isDeleting = ref(false)

    async function removeTemplate() {
      if (isDeleting.value === true) return

      try {
        await api.dayTemplates.delete(props.dayTemplate.id)
        emit('remove', props.dayTemplate.id)
      } catch (err) {
        console.error(err)
      }

      isDeleting.value = false
    }

    const editLink = `/my-templates/edit/${props.dayTemplate.id}`
    const periods = props.dayTemplate.periods.split(',')

    return { removeTemplate, editLink, isDeleting, periods }
  }
})
</script>

<style lang="scss" scoped>
.card {
  width: 280px !important;
  position: relative;

  &--editable {
    padding-bottom: 50px;
  }
}

.card-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
</style>
