
import { defineComponent, watch } from 'vue'
import { useApi } from '@/api'
import { DayTemplateDto } from 'service/__generated-api'
import DayTemplatePreview from '@/components/day-template-preview.vue'
import { useStore } from 'vuex'

export default defineComponent({
  components: { DayTemplatePreview },
  setup() {
    const store = useStore()
    const dayTemplatesRes = useApi<DayTemplateDto[]>(api =>
      api.dayTemplates.myTempaltes()
    )

    watch(dayTemplatesRes.data, dayTemplates => {
      store.commit('user/setDayTemplates', dayTemplates)
    })

    function onTemplateRemove(templateId: number) {
      const index = dayTemplatesRes.data.value?.findIndex(dayTemplate => {
        return dayTemplate.id === templateId
      })

      if (index !== undefined) {
        dayTemplatesRes.data.value?.splice(index, 1)
      }
    }

    return {
      isLoading: dayTemplatesRes.isLoading,
      dayTemplates: dayTemplatesRes.data,
      onTemplateRemove
    }
  }
})
