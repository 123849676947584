<template>
  <section class="flex">
    <div class="wrap">
      <h1 class="logo">Sparing Timer</h1>
      <a class="login-btn" :href="googleAuthUrl">
        <img
          src="/img/btn_google_signin_light_normal_web@2x.png"
          alt="Sign in with Google"
          class="login-img"
        />
      </a>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'login',
  layout: 'login',
  setup() {
    const googleAuthUrl = `${process.env.VUE_APP_API_URL}/google`

    return { googleAuthUrl }
  }
})
</script>

<style lang="scss" scoped>
.flex {
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
}

.wrap {
  animation: 0.5s ease-out Animation;
  width: 100%;
  text-align: center;
}

.login-btn {
  display: inline-block;
  margin-bottom: 120px;
  height: 50px;

  transition: opacity 0.2s;

  &:hover,
  &:focus {
    opacity: 0.8;
  }
}

.login-img {
  width: 250px;
}

.logo {
  text-align: center;
  display: block;
  width: 100%;
  margin-bottom: 20px;
  font-size: 60px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-weight: 700;
}

@keyframes Animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
