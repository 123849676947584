<template>
  <time-switcher @prev="previousMonth" @next="nextMonth">
    {{ format(currentDate, 'MM.yyyy') }} <br />
    <span class="subdate">
      {{ format(currentDate, 'LLLL') }}
    </span>
  </time-switcher>

  <div class="monthly-view" v-if="!isLoading">
    <table class="users-table table is-bordered is-narrow">
      <tr>
        <th :width="USER_LABEL_WIDTH - 1" height="57" class="user-label" />
      </tr>
      <tr v-for="user in users" :key="user.id">
        <user-in-table :user="user" />
      </tr>
    </table>
    <div class="table-container" ref="tableContainer">
      <table class="table is-bordered is-fullwidth is-narrow">
        <tr>
          <th
            :width="USER_LABEL_WIDTH"
            :style="{ width: `220px` }"
            class="user-label"
          >
            User / Date
          </th>
          <th :width="dayWidth" v-for="day in monthDaysList" :key="day">
            <router-link
              :to="`/daily/${format(day, 'yyyy-MM-dd')}`"
              class="day-link"
              :class="{
                'day-link--today':
                  format(day, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd')
              }"
            >
              <span class="day-name" v-text="format(day, 'EEEE')" />
              <br />
              {{ format(day, 'dd.MM') }}
            </router-link>
          </th>
        </tr>
        <user-row
          v-for="user in users"
          :key="user.id"
          :user="user"
          :days="monthDaysList"
        />
      </table>
    </div>

    <div class="is-flex is-justify-content-flex-end">
      <label class="checkbox">
        <input v-model="showPeriodsInMonthlyView" type="checkbox" />
        Show periods
      </label>
    </div>
  </div>

  <div v-if="isLoading">
    <span class="icon-text">
      <span class="icon">
        <i class="fas fa-spin fa-sync-alt"></i>
      </span>
      <span>Loading...</span>
    </span>
  </div>
</template>

<script lang="ts">
import {
  format,
  add,
  startOfMonth,
  parse,
  eachDayOfInterval,
  endOfMonth
} from 'date-fns'
import { computed, defineComponent, nextTick, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { formatDate, normalizeArray } from '@/utils'
import { useApi } from '@/api'
import { showPeriodsInMonthlyView } from '@/state/monthly-view'
import TimeSwitcher from '@/components/time-switcher.vue'
import UserRow from '@/components/calendars/team-monthly-user-row.vue'
import UserInTable from '@/components/user-in-table.vue'

const USER_LABEL_WIDTH = 220

export default defineComponent({
  components: { TimeSwitcher, UserRow, UserInTable },
  setup() {
    /**
     * Day width
     */
    const dayWidth = computed(() =>
      showPeriodsInMonthlyView.value ? 120 : 100
    )

    /**
     * Current month state
     */
    const route = useRoute()
    const routeMonth = `${route.params.formattedDate}`
    const currentDate = ref(
      /^\d{4}-\d\d-\d\d$/.test(routeMonth)
        ? parse(routeMonth, 'yyyy-MM-dd', new Date())
        : new Date()
    )
    const routeIds = route.query.teamsIds
    const teamsIds = normalizeArray(routeIds).map(Number)

    /**
     * Routing
     */
    const router = useRouter()
    function nextMonth() {
      router.push({
        ...route,
        params: {
          formattedDate: formatDate(
            startOfMonth(add(currentDate.value, { months: 1 }))
          )
        }
      })
    }

    function previousMonth() {
      router.push({
        ...route,
        params: {
          formattedDate: formatDate(
            endOfMonth(add(currentDate.value, { months: -1 }))
          )
        }
      })
    }

    /**
     * Month days
     */
    const firstDay = startOfMonth(currentDate.value)
    const lastDay = add(firstDay, { months: 1, days: -1 })
    const interval = { start: firstDay, end: lastDay }
    const monthDaysList = ref(eachDayOfInterval(interval))

    /**
     * Api call
     */
    const usersRes = useApi(api =>
      api.timeline.monthly(formatDate(currentDate.value), { teamsIds })
    )
    const isLoading = usersRes.isLoading
    const users = usersRes.data

    /**
     * Scroll view to current date
     */
    const tableContainer = ref<HTMLDivElement>()
    const dayOfTheMonth = parseInt(format(currentDate.value, 'd'))
    const scrollShift = (dayOfTheMonth - 2) * dayWidth.value

    watch(isLoading, async () => {
      await nextTick()
      if (tableContainer.value) {
        tableContainer.value.scrollLeft = scrollShift
      }
    })

    /**
     * Table width
     */
    const tableStyle = computed(() => {
      return {
        width: `${
          USER_LABEL_WIDTH + monthDaysList.value.length * dayWidth.value
        }px`
      }
    })

    /**
     * Return
     */
    return {
      currentDate,
      format,
      nextMonth,
      previousMonth,
      isLoading,
      monthDaysList,
      users,
      tableContainer,
      tableStyle,
      dayWidth,
      USER_LABEL_WIDTH,
      showPeriodsInMonthlyView
    }
  }
})
</script>

<style lang="scss" scoped>
.table {
  table-layout: fixed;
}

.monthly-view {
  position: relative;
}

.users-table {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: transparent;

  td {
    background-color: white;
  }

  th {
    border-right: none;
  }
}

.day-name {
  font-size: 14px;
  font-weight: normal;
}

.user-label {
  vertical-align: bottom;
  padding-bottom: 5px !important;
}

.day-link {
  display: block;
  color: inherit;
  transition: color 0.1s;

  &--today {
    color: rgb(73, 167, 30);
  }

  &:hover {
    color: rgb(69, 148, 250);
  }
}
</style>
