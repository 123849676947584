<template>
  <teleport to="#calendar-comments-teleport-target">
    <div
      class="comment-notification message"
      :class="{ 'comment-notification--visible': isActive }"
    >
      <div class="message-header">
        <p>
          {{ user.firstName }} {{ user.lastName }}
          <span class="has-text-weight-normal">{{ actionInfo }}</span>
          {{ day.formattedDate }}
        </p>
      </div>
      <div class="message-body">
        <span v-if="day.comment">{{ day.comment }}</span>
        <br v-if="day.comment && halfDaysMsg" />
        <span v-if="halfDaysMsg" class="half-days-msg">{{ halfDaysMsg }}</span>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { DayDto, TimelineUserDto } from 'service/__generated-api'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    user: {
      required: true,
      type: Object as PropType<TimelineUserDto>
    },
    day: {
      required: true,
      type: Object as PropType<DayDto>
    },
    isActive: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    halfDaysMsg(): string {
      return this.day?.halfNotAvailableStartTime
        ? `${this.day?.halfNotAvailableStartTime} - ${this.day?.halfNotAvailableEndTime} + half-day absence`
        : ''
    },
    actionInfo(): string {
      return this.day.halfNotAvailableStartTime && !this.day.comment
        ? ' is absent half-day on '
        : 'comments'
    }
  }
})
</script>

<style lang="scss" scoped>
.comment-notification {
  position: fixed;
  bottom: 40px;
  right: 40px;
  transition: all 0.2s;

  z-index: 2;
  max-width: 400px;

  opacity: 0;
  visibility: hidden;
  transform: translateX(40px);

  transition: all 0.2s;

  &--visible {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }

  .message-body {
    background-color: #eee;
  }
}

.half-days-msg {
  color: #4000ff;
}
</style>
