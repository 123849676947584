
import { defineComponent, ref, watch } from 'vue'
import PeriodPicker from './period-picker.vue'
import { periodsStringify } from '@/utils'

interface Period {
  id: number
  from: string
  to: string
}

let uid = 0

export default defineComponent({
  components: { PeriodPicker },
  props: {
    modelValue: {
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const periods = ref<Period[]>([])

    const startPeriods = props.modelValue?.split(',').map(period => {
      const [from, to] = period.split('-')
      return {
        from,
        to,
        id: uid++
      }
    }) ?? [
      {
        from: '08:00',
        to: '16:00',
        id: uid++
      }
    ]

    periods.value = startPeriods

    watch(
      periods,
      () => emit('update:modelValue', periodsStringify(periods.value)),
      { deep: true }
    )

    function addPeriod(): void {
      const lastPeriod = periods.value[periods.value.length - 1]

      periods.value.push({
        from: lastPeriod ? lastPeriod.to : '08:00',
        to: lastPeriod ? lastPeriod.to : '16:00',
        id: uid++
      })
    }

    function removePeriod(periodId: number) {
      const index = periods.value.findIndex(period => period.id === periodId)
      periods.value.splice(index, 1)
    }

    function updatePeriod(period: Period, updatedPeriod: Period) {
      period.from = updatedPeriod.from
      period.to = updatedPeriod.to
    }

    return {
      periods,
      addPeriod,
      removePeriod,
      updatePeriod
    }
  }
})
