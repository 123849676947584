import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { CurrentUserDto, DayTemplateDto } from '@/../service/__generated-api'

@Module({
  namespaced: true
})
export default class User extends VuexModule {
  isLogged = false
  id: number | null = null
  firstName: string | null = null
  lastName: string | null = null
  picture: string | null = null
  mattermostId: string | null = null
  role: string | null = null
  dayTemplates: DayTemplateDto[] = []
  worksPartTime = false

  @Mutation
  login(): void {
    this.isLogged = true
  }

  @Mutation
  workingTimeChange(isPartTime: boolean): void {
    this.worksPartTime = isPartTime
  }

  @Mutation
  logout(): void {
    this.isLogged = false
    window.localStorage.removeItem('token')
  }

  @Mutation
  setInfo(user: CurrentUserDto): void {
    this.id = user.id
    this.firstName = user.firstName
    this.lastName = user.lastName
    this.picture = user.picture
    this.dayTemplates = user.dayTemplates
    this.mattermostId = user.mattermostId
    this.role = user.role
    this.worksPartTime = user.worksPartTime
  }

  @Mutation
  setDayTemplates(dayTemplates: DayTemplateDto[]): void {
    this.dayTemplates = dayTemplates
  }
}
