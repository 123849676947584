<template>
  <div>
    <time-switcher @prev="previousDay" @next="nextDay">
      {{ format(currentDate, 'dd.MM.yy') }} <br />
      <span class="subdate">
        {{ format(currentDate, 'EEEE') }}
      </span>
    </time-switcher>
    <div class="daily-view" v-if="!isLoading && hours.length > 0">
      <div class="table-container">
        <table class="table is-bordered is-fullwidth is-narrow is-hoverable">
          <tr>
            <th>User</th>
            <th :colspan="hours.length" class="timeline-row">
              <div class="hours">
                <div v-for="hour in hours" :key="hour" class="hour">
                  {{ hour }}:00
                </div>
              </div>
            </th>
          </tr>
          <user-row
            v-for="user in users"
            :key="user.id"
            :hours="hours"
            :user="user"
          />
        </table>
      </div>
      <div class="legend">
        <span class="legend__item legend__item--remote">Remote</span>
        <span class="legend__item legend__item--office">Office</span>
      </div>
    </div>
    <div v-if="isLoading">
      <span class="icon-text">
        <span class="icon">
          <i class="fas fa-spin fa-sync-alt"></i>
        </span>
        <span>Loading...</span>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { useApi } from '@/api'
import { formatDate, normalizeArray } from '@/utils'
import { defineComponent, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { addDays, format, parse } from 'date-fns'
import UserRow from '@/components/calendars/team-daily-user-row.vue'
import TimeSwitcher from '@/components/time-switcher.vue'

export default defineComponent({
  components: { UserRow, TimeSwitcher },
  setup() {
    /**
     * Current day state
     */
    const route = useRoute()
    const routeDate = `${route.params.formattedDate}`
    const currentDate = ref(
      /^\d{4}-\d\d-\d\d$/.test(routeDate)
        ? parse(routeDate, 'yyyy-MM-dd', new Date())
        : new Date()
    )
    const routeIds = route.query.teamsIds
    const teamsIds = normalizeArray(routeIds).map(Number)

    /**
     * Api call
     */
    const usersRes = useApi(api =>
      api.timeline.daily(formatDate(currentDate.value), { teamsIds })
    )
    const isLoading = usersRes.isLoading
    const users = usersRes.data

    /**
     * User periods bars calc
     */
    let minHour = 23
    let maxHour = 0
    const hours = ref<number[]>([])

    watch(users, () => {
      if (!users.value) return

      for (const user of users.value) {
        const day = user.days[0]
        const periods = day ? day.periods.split(',') : ['8:00-16:00']
        const firstPeriod = periods[0]
        const lastPeriod = periods[periods.length - 1]

        const startHour = parseInt(firstPeriod) - 1
        if (startHour < minHour) minHour = startHour

        const endHour = parseInt(lastPeriod.split('-')[1])
        if (endHour > maxHour) maxHour = endHour
      }

      for (let i = minHour; i <= maxHour; i++) {
        hours.value.push(i)
      }

      // If nobody works this day:
      if (hours.value.length === 0) {
        hours.value = [8, 9, 10, 11, 12, 13, 14, 15, 16]
      }
    })

    /**
     * Routing
     */
    const router = useRouter()
    function nextDay() {
      router.push({
        ...route,
        params: { formattedDate: formatDate(addDays(currentDate.value, 1)) }
      })
    }

    function previousDay() {
      router.push({
        ...route,
        params: { formattedDate: formatDate(addDays(currentDate.value, -1)) }
      })
    }

    /**
     * Return
     */
    return {
      format,
      currentDate,
      nextDay,
      previousDay,
      users,
      hours,
      isLoading
    }
  }
})
</script>

<style lang="scss" scoped>
.table-container {
  margin: 0 0 5px;
}
.timeline-row {
  width: 100%;
  padding: 0 !important;
  margin: 0;
}
.hours {
  display: flex;
  height: 35px;
}
.hour {
  width: 100%;
  height: 100%;
  border-right: 1px solid #dbdbdb;
  padding: 5px;

  &:last-child {
    border: none;
  }
}
.legend {
  text-align: right;

  &__item {
    display: inline-block;
    margin-left: 10px;
    padding: 2px 6px;
    font-size: 14px;

    &::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 4px;
    }

    &--office::before {
      background-color: #7ce24d;
    }

    &--remote::before {
      background-color: #68ccf3;
    }
  }
}
</style>
