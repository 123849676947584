<template>
  <div class="container mt-3">
    <!-- Section -->
    <div class="section">
      <h1 class="title">My work</h1>
      <p class="mb-5">
        Choose your <strong>working hours</strong> to let the team know when
        you'll be available
      </p>

      <div class="calendar" ref="calendarEl"></div>

      <div class="calendar-loader-wrap">
        <div class="calendar-loader" v-if="isLoading">
          <span class="icon-text">
            <span class="icon">
              <i class="fas fa-spin fa-sync-alt"></i>
            </span>
            <span>Updating...</span>
          </span>
        </div>
      </div>

      <p class="is-size-7 calendar-selection-info">
        <span class="icon has-text-info">
          <i class="fas fa-info-circle"></i>
        </span>
        You can select multiple days by clicking and dragging. Weekends are not
        included in that selection
      </p>

      <!-- Days managment -->
      <div class="tabs-wrap">
        <transition name="quick-fade">
          <tabs
            v-if="!isLoading && selectedDays.length > 0"
            :selectedDays="selectedDays"
            :selectedDay="selectedDay"
            :active-tab="activeTab"
            @active-tab-change="tab => (activeTab = tab)"
            @apply-to-selection="applyToSelection"
            @apply-not-available-to-selection="applyNotAvailableToSelection"
            @remove-selection="removeSelection"
          />
        </transition>
      </div>
      <!-- e/o Days managment -->
    </div>
    <!-- e/o Section -->
  </div>
</template>

<script lang="ts">
import api from '@/api'
import Tabs from '@/components/my-work/tabs.vue'
import { formatDate, parseDate } from '@/utils'
import { MyWorkTab } from '@/interfaces'
import { Calendar } from '@fullcalendar/core'
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { addHours, eachDayOfInterval, isSameDay } from 'date-fns'
import calendarConfig from '@/components/my-work/calendar-config'
import { isBusinessDay } from '@/assets/isBusinessDay'
import { notAvailableArgs } from '@/utils'

import {
  CreateDayDto,
  DayDto,
  DayTemplateDto,
  DeleteDayDto
} from 'service/__generated-api'

export default defineComponent({
  components: { Tabs },
  setup() {
    const activeTab = ref(MyWorkTab.Template)
    let calendar: Calendar | null = null

    // TabLoader activates in applyResolver and terminates in calendar.events method
    // /shrug calendar refetchEvents doesn't return a promise
    const isLoading = ref(false)

    async function applyResolver<T>(promise: Promise<T>): Promise<T> {
      if (isLoading.value === true) Promise.reject()
      isLoading.value = true
      const res = await promise
      calendar?.refetchEvents()
      return res
    }

    async function applyToSelection(dayTemplate: DayTemplateDto) {
      const days: CreateDayDto[] = selectedDays.value.map(formattedDate => {
        return { formattedDate, ...dayTemplate }
      })

      await applyResolver(api.days.upsertMany(days))
    }

    async function applyNotAvailableToSelection({
      comment,
      halfNotAvailable,
      makingUpUnavailability,
      atConference,
      unplannedUnavailability,
      sick,
      notAvailable,
      halfNotAvailableStartTime,
      halfNotAvailableEndTime,
      markedByPartTimeUser
    }: notAvailableArgs) {
      const days: CreateDayDto[] = selectedDays.value.map(formattedDate => ({
        comment,
        halfNotAvailable,
        makingUpUnavailability,
        atConference,
        unplannedUnavailability,
        sick,
        halfNotAvailableStartTime,
        halfNotAvailableEndTime,
        formattedDate,
        periods: '',
        remote: false,
        holiday: false,
        notAvailable,
        markedByPartTimeUser
      }))

      await applyResolver(api.days.upsertMany(days))
    }

    async function removeSelection() {
      const daysToRemove: DeleteDayDto[] = selectedDays.value.map(
        formattedDate => ({ formattedDate })
      )

      await applyResolver(api.days.deleteMany(daysToRemove))
      activeTab.value = MyWorkTab.Template
    }

    const calendarEl = ref()
    const selectedDays = ref<string[]>([])
    const selectedDay = reactive<DayDto>({} as DayDto)

    onMounted(() => {
      calendar = new Calendar(calendarEl.value as HTMLDivElement, {
        ...calendarConfig,
        async events(info) {
          const formattedStartDate = formatDate(info.start)
          const formattedEndDate = formatDate(info.end)

          const daysRes = await api.days.getDays(
            formattedStartDate,
            formattedEndDate
          )

          isLoading.value = false
          selectedDays.value = []

          return daysRes.data.map(day => {
            return {
              id: day.monthYearUser,
              allDay: true,
              start: parseDate(day.formattedDate),
              extendedProps: { day }
            }
          })
        },
        select({ start, end }) {
          end = addHours(end, -1)
          const isTheSameDay = isSameDay(start, end)

          selectedDays.value = eachDayOfInterval({ start, end })
            .filter(date => isTheSameDay || isBusinessDay(formatDate(date)))
            .map(formatDate)

          if (selectedDays.value.length === 1) {
            const selectedEvent = calendar
              ?.getEvents()
              .find(
                event =>
                  event._def.extendedProps.day.formattedDate ===
                  formatDate(start)
              )?._def

            activeTab.value = selectedEvent?.extendedProps.day.periods
              ? MyWorkTab.Custom
              : MyWorkTab.Template

            if (selectedEvent) {
              Object.assign(selectedDay, selectedEvent.extendedProps.day)
            }
          }

          if (selectedDays.value.length > 1) {
            activeTab.value = MyWorkTab.Template
            selectedDay.periods = '08:00-16:00'
            selectedDay.remote = false
          }
        }
      })

      calendar.render()
    })

    return {
      applyToSelection,
      applyNotAvailableToSelection,
      removeSelection,
      calendarEl,
      selectedDays,
      selectedDay,
      activeTab,
      MyWorkTab,
      isLoading
    }
  }
})
</script>

<style lang="scss" scoped>
.date {
  font-weight: 400;
  font-size: 12px;
  color: grey;
}

:deep() .fc-daygrid-day-events {
  pointer-events: none;
}

.tabs-wrap {
  min-height: 430px;
}

.calendar-loader-wrap {
  position: relative;
}

.calendar-loader {
  position: absolute;
  right: 0;
  top: 0;
}

.calendar-selection-info {
  width: calc(100% - 120px);
}
</style>
