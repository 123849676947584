<template>
  <div class="select-day-template">
    <div class="mb-2">
      Apply
      <div class="select is-size-6 ml-1 mr-1">
        <select class="select-input" v-model="selectedId">
          <option
            v-for="{ id, name } in $store.state.user.dayTemplates"
            :key="id"
            :value="id"
          >
            {{ name }}
          </option>
        </select>
      </div>
      template:

      <button
        type="submit"
        class="button is-outlined is-success ml-2"
        v-text="submitText"
        @click="submit"
      />
    </div>

    <day-template-preview :dayTemplate="selectedDayTemplate" />
  </div>
</template>

<script lang="ts">
import { DayTemplateDto } from 'service/__generated-api'
import { defineComponent, ref, unref, watch } from 'vue'
import { useStore } from 'vuex'
import DayTemplatePreview from '@/components/day-template-preview.vue'

export default defineComponent({
  components: { DayTemplatePreview },
  props: ['submitText'],
  emits: ['submit'],
  setup(props, { emit }) {
    const store = useStore()
    const dayTemplates = store.state.user.dayTemplates as DayTemplateDto[]
    const selectedDayTemplate = ref(dayTemplates[0])
    const selectedId = ref(dayTemplates[0].id)

    watch(selectedId, (id: number) => {
      selectedDayTemplate.value = dayTemplates.find(
        (template: DayTemplateDto) => template.id === id
      ) as DayTemplateDto
    })

    function submit() {
      emit('submit', unref(selectedDayTemplate.value))
    }

    return { selectedId, submit, selectedDayTemplate }
  }
})
</script>

<style lang="scss" scoped>
.select,
.button {
  display: inline-block;
  position: relative;
  top: -7px;
}
</style>

function unRef(value: { periods: string; name: string; id: number }): any {
throw new Error('Function not implemented.') }
